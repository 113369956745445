import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import {
  NavigateOptions,
  URLSearchParamsInit,
  useSearchParams,
} from "react-router-dom";

type CompareDateRangeParams = {
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit)
      | undefined,
    navigateOpts?: NavigateOptions | undefined
  ) => void;
};

const CompareDateRangeParamsContext = createContext<
  CompareDateRangeParams | undefined
>(undefined);

type CompareDateRangeParamsProviderProps = PropsWithChildren<{}>;

export function CompareDateRangeParamsProvider(
  props: CompareDateRangeParamsProviderProps
) {
  const { children } = props;

  const [searchParams, setSearchParams] = useSearchParams({
    leftStartDate: "",
    leftEndDate: "",
    rightStartDate: "",
    rightEndDate: "",
  });

  const value = useMemo(
    () => ({
      searchParams,
      setSearchParams,
    }),
    [searchParams, setSearchParams]
  );

  return (
    <CompareDateRangeParamsContext.Provider value={value}>
      {children}
    </CompareDateRangeParamsContext.Provider>
  );
}

export const useCompareDateRangeParams = (): CompareDateRangeParams => {
  const compareDateRangeParams = useContext(CompareDateRangeParamsContext);

  if (compareDateRangeParams === undefined) {
    throw new Error(
      `useCompareDateRangeParams must be used within a CompareDateRangeParamsProvider`
    );
  }

  return compareDateRangeParams;
};
