import styled from "styled-components/macro";

export const MealsAnalysisResponseTableCompareModalBodyContainer = styled.div`
  height: 700px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;
