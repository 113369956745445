import { CompareDateRange } from "../../compare-date-range/compare-date-range";
import { useCompareDateRange } from "../../context/compare-date-context";

export const CompareRightResponseHeader = () => {
  const {
    temporalStartDate,
    temporalEndDate,
    selectedDates,
    onDatesChange,
    handleDecrementDates,
    handleIncrementDates,
  } = useCompareDateRange();

  return (
    <CompareDateRange
      temporalStartDate={temporalStartDate.right}
      temporalEndDate={temporalEndDate.right}
      onDatesChange={onDatesChange}
      handleIncrementDates={() => handleIncrementDates("Right")}
      handleDecrementDates={() => handleDecrementDates("Right")}
      selectedDates={selectedDates.right}
      compareSide="Right"
    />
  );
};
