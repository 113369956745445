import { DayAggregate } from "../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import {
  DailyChartCardHeader,
  DailyChartCardHeaderTitleContainer,
  DailyChartRangesContainer,
} from "../../../daily-analysis/daily-charts/daily-chart/styled-daily-chart";
import {
  DailyFixedSummaryChartChart,
  DailyFixedSummaryChartContainerContainer,
} from "./styled-daily-fixed-summary-chart-container";
import { useBglAnalysis } from "../../../context/loadable-bgl-analysis-context";
import { CgmSyncfusionChart } from "../../../../../../components/cgm-syncfusion-chart/cgm-syncfusion-chart";
import { DailyChartRanges } from "../../../daily-analysis/daily-charts/daily-chart/daily-chart-ranges/daily-chart-ranges";
import { DailyFixedSummaryChartCard } from "./daily-fixed-summary-chart-card/daily-fixed-summary-chart-card";

type DailyFixedSummaryChartContainerProps = {
  dayAggregate: DayAggregate;
  zIndex: number;
};

export const DailyFixedSummaryChartContainer = (
  props: DailyFixedSummaryChartContainerProps
) => {
  const { dayAggregate, zIndex } = props;
  const { patientDTO } = useBglAnalysis();

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const displayDate = `${dayAggregate.date.toLocaleString(
    "en-US",
    dateOptions
  )}`;

  const cgmReadings = dayAggregate.readings.filter(
    (reading) => reading.source === "Libreview"
  );

  if (dayAggregate.date.toString() === "2024-05-21" && patientDTO.id === 130) {
    return <></>;
  }

  return (
    <DailyFixedSummaryChartContainerContainer>
      <DailyFixedSummaryChartChart>
        <DailyChartCardHeader>
          <DailyChartCardHeaderTitleContainer>
            <p>{displayDate}</p>
            <span>Blood glucose reading</span>
          </DailyChartCardHeaderTitleContainer>
          <DailyChartRangesContainer>
            {cgmReadings.length > 0 && (
              <DailyChartRanges
                smallCircles={false}
                dayAggregate={dayAggregate}
                patientDTO={patientDTO}
              />
            )}
          </DailyChartRangesContainer>
        </DailyChartCardHeader>
        <CgmSyncfusionChart
          patientDTO={patientDTO}
          dayAggregate={dayAggregate}
          zindex={zIndex}
          hasTooltip={false}
          enableLegend
          cgmChartMarkerTemplate={["Patient Meals", "Insulin", "Activities"]}
        />
      </DailyFixedSummaryChartChart>
      <DailyFixedSummaryChartCard dayAggregate={dayAggregate} />
    </DailyFixedSummaryChartContainerContainer>
  );
};
