import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  flexColCenterCenter,
  mediumFont,
  mediumSemiBoldFont,
} from "../../../../styles/classes/gloabl-classes";
import { lightShadowAlt } from "../../../../styles/global-style";

export const CgmTooltipContainer = styled.div`
  pointer-events: auto;
  background: ${({ theme }) => theme.colors.containerColor};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
  ${lightShadowAlt}
`;

export const CgmTooltipHeader = styled.div`
  background: ${({ theme }) => theme.newColors.otidaO2};
  ${flexCenterSpaceBetween}
  padding: 16px;

  p {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.titleColor};
    display: grid;
    margin: 0px;
  }

  span {
    font-size: 12px;
    margin-left: 0px;
    font-weight: 700;
    color: ${({ theme }) => theme.newColors.otida03};
  }
`;

export const CgmPatientMealsMacros = styled.div`
  padding: 0.5rem 0.8rem 0rem;
`;

export const CgmTooltipBody = styled.div`
  padding: 0.5rem 1rem;

  button {
    margin-left: auto;
  }
`;

export const CgmTooltipImageContainer = styled.div`
  max-width: 290px;
  height: 150px;
  margin: 0 auto;
  ${flexColCenterCenter}
  overflow: hidden;

  img {
    max-width: 290px;
    border-radius: 6px;
    height: 150px;
    margin: 16px auto 0px;
    cursor: pointer;
  }
`;

export const CGMTooltipMealIngredentContainer = styled.div`
  ${flexCenterSpaceBetween}
  margin: 8px;

  p {
    ${mediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 150px;

    :hover {
      white-space: normal;
      overflow: visible;
    }
  }

  span {
    ${mediumSemiBoldFont}
    color: ${({ theme }) => theme.newColors.textColorAlt};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    :hover {
      white-space: normal;
      overflow: visible;
    }
  }
`;
