import { useCallback } from "react";
import { useMealsAnalysisSelectedTableRows } from "../../../../context/meals-analysis-selected-table-rows-context";
import { MealsAnalysisResponseTableCell } from "../styled-meals-analysis-response-table-cells";

type MealsAnalysisResponseCheckboxTableCellProps = {
  index: number;
};

export const MealsAnalysisResponseCheckboxTableCell = (
  props: MealsAnalysisResponseCheckboxTableCellProps
) => {
  const { index } = props;

  const {
    selectedTableRows,
    setSelectedTableRows,
  } = useMealsAnalysisSelectedTableRows();

  const handleAddRow = useCallback(
    (index: number) => {
      if (!selectedTableRows.includes(index)) {
        setSelectedTableRows([...selectedTableRows, index]);
      }
    },
    [selectedTableRows, setSelectedTableRows]
  );

  const handleRemoveRow = useCallback(
    (index: number) => {
      if (selectedTableRows.includes(index)) {
        setSelectedTableRows(
          selectedTableRows.filter((item) => item !== index)
        );
      }
    },
    [selectedTableRows, setSelectedTableRows]
  );

  return (
    <MealsAnalysisResponseTableCell>
      <input
        type="checkbox"
        checked={selectedTableRows.includes(index)}
        disabled={
          selectedTableRows.length >= 3 &&
          !selectedTableRows.some((item) => item === index)
        }
        onChange={(e) => {
          if (e.target.checked) {
            handleAddRow(index);
          } else {
            handleRemoveRow(index);
          }
        }}
      />
    </MealsAnalysisResponseTableCell>
  );
};
