import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../../../styles/classes/gloabl-classes";

export const NutritionSummaryCardCenter = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const NutritionSummaryCardCenterList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin: 0;

  div {
    width: 50%;
  }

  div ~ div {
    border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
  }
`;

export const NutritionSummaryCardCenterListItem = styled.li`
  ${flexCenter}
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 0px 3.2px 0px 8px;
  text-align: left;
  height: 35px;

  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.textColor};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    line-height: 1.4;
    flex: 1;
    text-transform: capitalize;
  }

  span {
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    width: 29px;
  }

  :nth-child(odd) {
    background: ${({ theme }) => theme.newColors.oddBackground};
  }
`;
