import { useParams } from "react-router-dom";
import { LoadableDataLoading } from "../../../../components/loadable-data-loading";
import { LoadableBglAnalysisProvider } from "../context/loadable-bgl-analysis-context";
import { useGetPatient } from "../../../../hooks/patient-hooks/use-get-patient";
import { useCallback } from "react";
import { useLoadableData } from "../../../../hooks/use-loadable-data";
import { DailyFixedSummaryCharts } from "./daily-analysis-charts/daily-fixed-summary-charts";
import { DateRangeProviders } from "../../../../components/date-range/context/date-range-providers";

export const DailyFixedSummary = () => {
  const { id } = useParams();
  const getPatient = useGetPatient();

  const loadableBGLAnalysisCallback = useCallback(async () => {
    const patientId = parseInt(id!);

    const [patientDTO] = await Promise.all([getPatient(patientId)]);

    return {
      patientDTO,
    };
  }, [id, getPatient]);

  const loadableBglAnalysis = useLoadableData(loadableBGLAnalysisCallback);

  return (
    <LoadableBglAnalysisProvider loadableBglAnalysis={loadableBglAnalysis}>
      <LoadableDataLoading
        state={loadableBglAnalysis.state}
        successComponent={() => (
          <DateRangeProviders startWithUndefined>
            <DailyFixedSummaryCharts />
          </DateRangeProviders>
        )}
      />
    </LoadableBglAnalysisProvider>
  );
};
