import { DateRangeProviders } from "../../../../components/date-range/context/date-range-providers";
import { BgmResponseHeader } from "./bgm-response-header/bgm-response-header";
import { BGMResponse } from "./bgm-response/bgm-response";

export const Bgm = () => {
  return (
    <DateRangeProviders startWithUndefined={false}>
      <BgmResponseHeader />
      <BGMResponse />
    </DateRangeProviders>
  );
};
