import {
  DailyChartRangesContainer,
  DailyChartCardHeaderTitleContainer,
  DailyChartCard,
  DailyChartChartContainer,
  DailyChartLegend,
  DailyChartLegendPoint,
  DailyChartContainer,
  DailyChartCardHeader,
} from "./styled-daily-chart";
import { DayAggregate } from "../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { useBglAnalysis } from "../../../context/loadable-bgl-analysis-context";
import { CgmSyncfusionChart } from "../../../../../../components/cgm-syncfusion-chart/cgm-syncfusion-chart";
import { DailyChartRanges } from "./daily-chart-ranges/daily-chart-ranges";
import { NutritionSummary } from "./nutrition-summary/nutrition-summary";
import { FEATURE_FLAG_NUTRITION_SUMMARY_FROM_MEALS } from "../../../../../../constants/config";
import { StaticNutritionSummary } from "./nutrition-summary/static-nutrition-summary/static-nutrition-summary";

export type DailyChartProps = {
  dayAggregate: DayAggregate;
  zIndex: number;
};

export function DailyChart(props: DailyChartProps) {
  const { dayAggregate, zIndex } = props;

  const { patientDTO } = useBglAnalysis();

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const displayDate = `${dayAggregate.date.toLocaleString(
    "en-US",
    dateOptions
  )}`;

  if (dayAggregate.date.toString() === "2024-05-21" && patientDTO.id === 130) {
    return <></>;
  }

  return (
    <DailyChartContainer>
      <DailyChartCard>
        <DailyChartCardHeader>
          <DailyChartCardHeaderTitleContainer>
            <p>{displayDate}</p>
            <span>Blood glucose reading</span>
          </DailyChartCardHeaderTitleContainer>
          <DailyChartRangesContainer>
            {dayAggregate.patientCGMEntryDTOs.length > 0 && (
              <DailyChartRanges
                smallCircles={false}
                dayAggregate={dayAggregate}
                patientDTO={patientDTO}
              />
            )}
          </DailyChartRangesContainer>
        </DailyChartCardHeader>
        <DailyChartChartContainer>
          <CgmSyncfusionChart
            patientDTO={patientDTO}
            dayAggregate={dayAggregate}
            zindex={zIndex}
            hasTooltip
            enableLegend={false}
          />
        </DailyChartChartContainer>
        <DailyChartLegend>
          <DailyChartLegendPoint $bgm>
            <img src="/img/cgm-chart/bgm-readings.png" alt="readings" />
            <span>BGM Reading</span>
          </DailyChartLegendPoint>
          <DailyChartLegendPoint>
            <img src="/img/cgm-chart/meals.png" alt="meals" />
            <span>Meals</span>
          </DailyChartLegendPoint>
          <DailyChartLegendPoint>
            <img src="/img/cgm-chart/activity.png" alt="activity" />
            <span>Activity</span>
          </DailyChartLegendPoint>
          <DailyChartLegendPoint>
            <img src="/img/cgm-chart/medications.png" alt="medications" />
            <span>Medications</span>
          </DailyChartLegendPoint>
          <DailyChartLegendPoint>
            <img src="/img/cgm-chart/insulin.png" alt="insulin" />
            <span>Insulin</span>
          </DailyChartLegendPoint>
        </DailyChartLegend>
      </DailyChartCard>
      {FEATURE_FLAG_NUTRITION_SUMMARY_FROM_MEALS ? (
        <NutritionSummary patientMealDTOs={dayAggregate.patientMealDTO} />
      ) : (
        <StaticNutritionSummary date={dayAggregate.date} />
      )}
    </DailyChartContainer>
  );
}
