import { Temporal } from "temporal-polyfill";
import { MacrosBar } from "../../../../../../../../../../components/macros-bar/macros-bar";
import { PatientMealResponseDTO } from "../../../../../../../../../../models/patient-meal-response-dtos/patient-meal-response-dto";
import {
  MealsAnalysisResponseTableCompareMealsList,
  MealsAnalysisResponseTableCompareMealsListImageContainer,
  MealsAnalysisResponseTableCompareMealsListItem,
  MealsAnalysisResponseTableCompareMealsListItemHeader,
  MealsAnalysisResponseTableCompareMealsListMacrosContainer,
} from "./styled-meals-analysis-response-table-compare-meals";
import { MealsAnalysisResponseTableCompareMealsDropdown } from "./meals-analysis-response-table-compare-meals-dropdown/meals-analysis-response-table-compare-meals-dropdown";

type MealsAnalysisResponseTableCompareMealsProps = {
  patientMealResponseDTOs: PatientMealResponseDTO[];
};

export const MealsAnalysisResponseTableCompareMeals = (
  props: MealsAnalysisResponseTableCompareMealsProps
) => {
  const { patientMealResponseDTOs } = props;

  const patientMealDTOs = patientMealResponseDTOs.map(
    (patientMealResponseDTO) => patientMealResponseDTO.patientMeal
  );

  const listItems = patientMealDTOs.map((patientMealDTO, key) => {
    const patientMealMacrosSummary = patientMealDTO.patientMealMacrosSummary;
    const carbCounterV1MealSummary = patientMealDTO.carbCounterV1MealSummary;
    const indicatorColor =
      key === 0 ? "#c8c2ae" : key === 1 ? "#7c6354" : "#b66d0d";

    const displayDateTime = Temporal.PlainDateTime.from(
      `${patientMealDTO.date}T${patientMealDTO.time}`
    ).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });

    return (
      <MealsAnalysisResponseTableCompareMealsListItem key={key}>
        <MealsAnalysisResponseTableCompareMealsListItemHeader
          $indicatorColor={indicatorColor}
        >
          <p>
            {patientMealDTO.tag} <span></span>
          </p>
          <span>{displayDateTime}</span>
        </MealsAnalysisResponseTableCompareMealsListItemHeader>
        <MealsAnalysisResponseTableCompareMealsListImageContainer>
          <img
            src={patientMealDTO.patientMealImages[0].publicUrl}
            alt={`Meal ${displayDateTime}`}
          />
        </MealsAnalysisResponseTableCompareMealsListImageContainer>
        <MealsAnalysisResponseTableCompareMealsListMacrosContainer>
          {carbCounterV1MealSummary ? (
            <MacrosBar
              totalKCals={carbCounterV1MealSummary.totalKcal}
              carbsPercentage={~~carbCounterV1MealSummary.carbsKcalPercentage}
              fatsPercentage={~~carbCounterV1MealSummary.fatKcalPercentage}
              proteinPercentage={
                ~~carbCounterV1MealSummary.proteinKcalPercentage
              }
              displayGrams={{
                displayOnlyGrams: false,
                carbsGm: ~~carbCounterV1MealSummary.totalCarbsGm,
                fatsGm: ~~carbCounterV1MealSummary.totalFatGm,
                proteinGm: ~~carbCounterV1MealSummary.totalProteinGm,
              }}
            />
          ) : (
            <MacrosBar
              totalKCals={patientMealMacrosSummary.kCals}
              carbsPercentage={~~patientMealMacrosSummary.carbsPercentage}
              fatsPercentage={~~patientMealMacrosSummary.fatPercentage}
              proteinPercentage={~~patientMealMacrosSummary.proteinPercentage}
              displayGrams={{
                displayOnlyGrams: false,
                carbsGm: ~~patientMealMacrosSummary.carbsGm,
                fatsGm: ~~patientMealMacrosSummary.fatGm,
                proteinGm: ~~patientMealMacrosSummary.proteinGm,
              }}
            />
          )}
        </MealsAnalysisResponseTableCompareMealsListMacrosContainer>
        <MealsAnalysisResponseTableCompareMealsDropdown
          patientMealDTO={patientMealDTO}
          carbCounterV1MealSummary={carbCounterV1MealSummary}
        />
      </MealsAnalysisResponseTableCompareMealsListItem>
    );
  });

  return (
    <MealsAnalysisResponseTableCompareMealsList>
      {listItems}
    </MealsAnalysisResponseTableCompareMealsList>
  );
};
