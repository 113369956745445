import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  flexCol,
  flexColCenterCenter,
} from "../../../../../../../../../../styles/classes/gloabl-classes";
import { lightShadowAlt } from "../../../../../../../../../../styles/global-style";

export const MealsAnalysisResponseTableCompareMealsList = styled.ul`
  margin: 0px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 0px 8px;
`;

export const MealsAnalysisResponseTableCompareMealsListItem = styled.li`
  ${flexCol}
  background: ${({ theme }) => theme.colors.containerColor};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  width: 380px;
  ${lightShadowAlt}
  `;

export const MealsAnalysisResponseTableCompareMealsListItemHeader = styled.div<{
  $indicatorColor: string;
}>`
  background: ${({ theme }) => theme.newColors.otidaO2};
  ${flexCenterSpaceBetween}
  padding: 16px;
  border-top: 5px solid ${({ $indicatorColor }) => $indicatorColor};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  p {
    ${flexCenter}
    justify-content: space-between;
    gap: 0px 4px;
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
    width: 40%;
  }

  span {
    font-size: 12px;
    margin-left: 0px;
    font-weight: 700;
    color: ${({ theme }) => theme.newColors.otida03};
  }
`;

export const MealsAnalysisResponseTableCompareMealsListImageContainer = styled.div`
  max-width: 290px;
  height: 150px;
  margin: 0 auto;
  ${flexColCenterCenter}
  overflow: hidden;

  img {
    max-width: 290px;
    border-radius: 6px;
    height: 150px;
    margin: 16px auto 0px;
    cursor: pointer;
  }
`;

export const MealsAnalysisResponseTableCompareMealsListMacrosContainer = styled.div`
  font-size: 12px;
`;
