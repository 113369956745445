import { useParams } from "react-router-dom";
import { Loading } from "../../../../../components/loading";
import { useGetPatientMeals } from "../../../../../hooks/patient-meal-hooks/use-get-patient-meals";
import { useCallback } from "react";
import { NutritionAnalysisResponse } from "./nutrition-analysis-response/nutrition-analysis-response";
import { NutritionAnalysisDataProvider } from "../context/nutrition-analysis-data-context";
import { useDateRange } from "../../../../../components/date-range/context/date-range-context";

export const NutritionAnalysisBody = () => {
  const { id } = useParams();

  const { temporalStartDate, temporalEndDate } = useDateRange();
  const getPatientMeals = useGetPatientMeals();

  const loadPatientMeals = useCallback(async () => {
    const patientID = parseInt(id!);

    const patientMeals = await getPatientMeals(
      patientID,
      undefined,
      temporalStartDate,
      temporalEndDate
    );

    return { patientMeals };
  }, [id, getPatientMeals, temporalStartDate, temporalEndDate]);

  return (
    <Loading
      load={loadPatientMeals}
      successComponent={(response) => (
        <NutritionAnalysisDataProvider patientMeals={response.patientMeals}>
          <NutritionAnalysisResponse />
        </NutritionAnalysisDataProvider>
      )}
    />
  );
};
