import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  PropsWithChildren,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Temporal } from "temporal-polyfill";
import { generateDateRange } from "../utils/generate-date-range";
import { getDatesInRange } from "../utils/get-dates-in-range";
import { useDateRangeParams } from "./date-range-params-context";

export type DatesWithinRange = { weekDay: string; date: string };

export type SelectedDates = {
  temporalStartDate?: string;
  temporalEndDate?: string;
  count: number;
  datesWithinRange: DatesWithinRange[];
};

type DateRangePickerRef = RefObject<DateRangePickerComponent>;

type DateRange = {
  startDate?: Date;
  endDate?: Date;
  temporalStartDate?: string;
  temporalEndDate?: string;
  onDatesChange: (e: any) => void;
  handleIncrementDates: () => void;
  handleDecrementDates: () => void;
  selectedDates: SelectedDates;
  dateRangePickerRef: DateRangePickerRef;
  onDateRangePickerClick: () => void | undefined;
  dateRangeDropdownHandler: (days: 15 | 31 | 91) => void;
  resetDates: () => void;
};

const DateRangeContext = createContext<DateRange | undefined>(undefined);

type DateRangeProviderProps = PropsWithChildren<{
  startWithUndefined: boolean;
}>;

export function DateRangeProvider(props: DateRangeProviderProps) {
  const { children, startWithUndefined } = props;

  const { searchParams, setSearchParams } = useDateRangeParams();

  const searchParamsStartDate = searchParams.get("startDate");
  const isSearchParamsStartDate =
    searchParamsStartDate !== undefined &&
    searchParamsStartDate !== null &&
    searchParamsStartDate !== "";

  const searchParamsEndDate = searchParams.get("endDate");
  const isSearchParamsEndDate =
    searchParamsEndDate !== undefined &&
    searchParamsEndDate !== null &&
    searchParamsEndDate !== "";

  const dateRangePickerRef: DateRangePickerRef = useRef<
    DateRangePickerComponent
  >(null);

  const onDateRangePickerClick = useCallback(
    () => dateRangePickerRef.current?.show(),
    []
  );

  let currentDate = useMemo(() => new Date(), []);
  let twoWeeksAgo = useMemo(
    () => new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000),
    [currentDate]
  );

  const isStartDateUndefined = startWithUndefined && !isSearchParamsStartDate;
  const isEndDateUndefined = startWithUndefined && !isSearchParamsEndDate;

  const [startDate, setStartDate] = useState<Date | undefined>(
    isSearchParamsStartDate
      ? new Date(searchParamsStartDate)
      : isStartDateUndefined
      ? undefined
      : twoWeeksAgo
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    isSearchParamsEndDate
      ? new Date(searchParamsEndDate)
      : isEndDateUndefined
      ? undefined
      : currentDate
  );

  const temporalStartDate = startDate
    ? Temporal.PlainDate.from(startDate.toISOString().split("T")[0]).toString()
    : undefined;

  const temporalEndDate = endDate
    ? Temporal.PlainDate.from(endDate.toISOString().split("T")[0]).toString()
    : undefined;

  useEffect(() => {
    setSearchParams(
      {
        startDate: temporalStartDate!,
        endDate: temporalEndDate!,
      },
      { replace: true }
    );
  }, [temporalStartDate, setSearchParams, temporalEndDate, startWithUndefined]);

  const dateRangeDropdownHandler = useCallback(
    (days: 15 | 31 | 91) => {
      setStartDate(
        new Date(currentDate.getTime() - (days - 1) * 24 * 60 * 60 * 1000)
      );
      setEndDate(new Date());
    },
    [currentDate]
  );

  const onDatesChange = useCallback(
    (e: any) => {
      if (e.target.value !== null) {
        setStartDate(e.target.value[0]);
        setEndDate(e.target.value[1]);
      }
    },
    [setStartDate, setEndDate]
  );

  const handleIncrementDates = useCallback(() => {
    if (startDate && endDate) {
      const newStartDate = new Date(endDate);
      newStartDate.setDate(newStartDate.getDate() + 1);
      setStartDate(newStartDate);

      const newEndDate = new Date(newStartDate);
      newEndDate.setDate(
        newEndDate.getDate() +
          generateDateRange(startDate, endDate).numberOfDays
      );
      setEndDate(newEndDate);
    }
  }, [setStartDate, setEndDate, endDate, startDate]);

  const handleDecrementDates = useCallback(() => {
    if (startDate && endDate) {
      const newEndDate = new Date(startDate);
      newEndDate.setDate(newEndDate.getDate() - 1);
      setEndDate(newEndDate);

      const newStartDate = new Date(newEndDate);
      newStartDate.setDate(
        newStartDate.getDate() -
          generateDateRange(startDate, endDate).numberOfDays
      );
      setStartDate(newStartDate);
    }
  }, [setStartDate, setEndDate, endDate, startDate]);

  const selectedDates = useMemo(
    () => ({
      temporalStartDate,
      temporalEndDate,
      datesWithinRange:
        startDate && endDate ? getDatesInRange(startDate, endDate) : [],
      count:
        startDate && endDate
          ? generateDateRange(startDate, endDate).numberOfDays + 1
          : 0,
    }),
    [temporalStartDate, temporalEndDate, startDate, endDate]
  );

  const resetDates = useCallback(() => {
    setStartDate(undefined);
    setEndDate(undefined);
  }, [setStartDate, setEndDate]);

  const value: DateRange = useMemo(
    () => ({
      startDate,
      endDate,
      temporalStartDate,
      temporalEndDate,
      onDatesChange,
      handleIncrementDates,
      handleDecrementDates,
      selectedDates,
      dateRangePickerRef,
      onDateRangePickerClick,
      dateRangeDropdownHandler,
      resetDates,
    }),
    [
      startDate,
      endDate,
      temporalStartDate,
      temporalEndDate,
      onDatesChange,
      handleIncrementDates,
      handleDecrementDates,
      selectedDates,
      dateRangePickerRef,
      onDateRangePickerClick,
      dateRangeDropdownHandler,
      resetDates,
    ]
  );

  return (
    <DateRangeContext.Provider value={value}>
      {children}
    </DateRangeContext.Provider>
  );
}

export function useDateRange(): DateRange {
  const DateRange = useContext(DateRangeContext);

  if (DateRange === undefined) {
    throw new Error(`useDateRange must be used within DateRangeProvider`);
  }

  return DateRange;
}
