import { Temporal } from "temporal-polyfill";
import { PatientInsulinLogDTO } from "../../../../../../../../../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { PatientCGMEntryDTO } from "../../../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { CgmTooltipProps } from "../../../../../../../../../../../../components/cgm-syncfusion-chart/utils/cgm-tooltip/cgm-tooltip";

type InsulinSeries = {
  x: number;
  y: number;
  tooltip: CgmTooltipProps;
};

export function buildInsulinSeries(
  startPlainDateTime: Temporal.PlainDateTime,
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientInsulinLogDTOs: PatientInsulinLogDTO[]
): InsulinSeries[] {
  const insulinSeries: InsulinSeries[] = [];

  for (const patientInsulinLogDTO of patientInsulinLogDTOs) {
    const jsDate = new Date(patientInsulinLogDTO.date);

    const year = jsDate.getFullYear();
    const month = jsDate.getMonth() + 1;

    let realMonth = ``;
    if (month >= 0 && month <= 9) {
      realMonth = `0${month.toString()}`;
    } else {
      realMonth = month.toString();
    }

    const day = jsDate.getDate();
    let realDay = ``;
    if (day >= 0 && day <= 9) {
      realDay = `0${day.toString()}`;
    } else {
      realDay = day.toString();
    }

    const realDate = `${year}-${realMonth}-${realDay}`;

    const dateTime = Temporal.PlainDateTime.from(
      `${realDate}T${patientInsulinLogDTO.time}`
    );

    const x = dateTime.since(startPlainDateTime).total({ unit: "minutes" });
    const y = patientCGMEntryDTOs[0].glucoseMGPerDL;

    const tooltip: CgmTooltipProps = {
      x: new Date(`${realDate}T${patientInsulinLogDTO.time}`),
      y: patientInsulinLogDTO.dose,
      kind: {
        kind: "Insulin",
        data: patientInsulinLogDTO,
      },
    };

    const currentPlainDateTime = Temporal.PlainDateTime.from(
      `${patientCGMEntryDTOs[0].date}T${patientCGMEntryDTOs[0].time}`
    );

    insulinSeries.push({
      x:
        x === 0
          ? currentPlainDateTime
              .since(startPlainDateTime)
              .total({ unit: "minutes" })
          : x,
      y,
      tooltip,
    });
  }

  return insulinSeries;
}
