import { MarkerSettingsModel } from "@syncfusion/ej2-react-charts";

export const BgmMarker: MarkerSettingsModel = {
  visible: true,
  border: { width: 0 },
  shape: "Image",
  imageUrl: "/img/cgm-chart/bgm-readings.png",
  width: 15,
  height: 15,
};
