import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumRegularFont,
  regularSemiBoldFont,
} from "../../../../../../../../../styles/classes/gloabl-classes";
import { DailyFixedSummaryChartCardContainer } from "../../../../../../daily-fixed-summary/daily-analysis-charts/daily-fixed-summary-chart-container/daily-fixed-summary-chart-card/styled-daily-fixed-summary-chart-card";

export const AgpReportResponseChartModalBodyContainer = styled.div`
  position: relative;
  ${flexCenterSpaceBetween}
  padding: 12px 0px;
  gap: 0px 8px;

  ${DailyFixedSummaryChartCardContainer} {
    height: 100%;
  }
`;

export const AgpReportResponseChartModalBodyIcon = styled.span`
  user-select: none;
  cursor: pointer;
`;

export const AgpReportResponseChartModalBodyChartContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 12px;
  width: 70%;
`;

export const AgpReportResponseChartModalBodyChartHeader = styled.div`
  ${flexCenterSpaceBetween}
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  height: 70px;
`;

export const AgpReportResponseChartModalBodyChartTitle = styled.div`
  p {
    ${regularSemiBoldFont}
    margin: 0px;
  }

  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
