import { MarkerSettingsModel } from "@syncfusion/ej2-react-charts";
import { MarkerTemplateActivitiesContainer } from "./styled-marker-template";
import { ActivitiesSeriesDataSource } from "../../series/build-activities-series";

export function buildActivitiesMarkerTemplate(): MarkerSettingsModel {
  const template: any = markerTemplate;

  return {
    visible: true,
    border: { width: 0 },
    shape: "Image",
    imageUrl: "/img/cgm-chart/activity.png",
    width: 20,
    height: 20,
    dataLabel: {
      visible: true,
      template: template,
    },
  };
}

function markerTemplate(args: any) {
  const dataSource: ActivitiesSeriesDataSource[] =
    args.series.properties.dataSource;

  const findCurrentDataSource = dataSource.find(
    (item) => item.y === args.point.y
  );

  if (
    findCurrentDataSource &&
    findCurrentDataSource.tooltip.kind.kind === "Activities"
  ) {
    const activity = findCurrentDataSource.tooltip.kind.data.activity;

    return (
      <MarkerTemplateActivitiesContainer>
        <p>
          {activity?.name}{" "}
          <span>
            {findCurrentDataSource.tooltip.kind.data.durationMins} Minutes
          </span>
        </p>
      </MarkerTemplateActivitiesContainer>
    );
  }

  return <></>;
}
