import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../../../../../styles/classes/gloabl-classes";

export const FatSummaryContainer = styled.div`
  ${flexCenterSpaceBetween}
  margin-bottom: 8px;
  gap: 0px 16px;
`;

export const FatSummaryText = styled.p<{ isGood: boolean }>`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  margin: 0;
  color: ${({ theme }) => theme.colors.textColor};

  span {
    color: ${({ theme, isGood }) =>
      isGood ? theme.colors.success : theme.colors.danger};
  }
`;
