import { DateRangeProviders } from "../../../../components/date-range/context/date-range-providers";
import { AGPReportResponse } from "./agp-report-response/agp-report-response";

export const AGPReport = () => {
  return (
    <DateRangeProviders startWithUndefined={false}>
      <AGPReportResponse />
    </DateRangeProviders>
  );
};
