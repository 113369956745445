import { Temporal } from "temporal-polyfill";
import { PatientCGMEntryDTO } from "../../../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientMealDTO } from "../../../../../../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { calculateYaxisValue } from "../../../../../../meals-analysis-response-table-rows/meals-analysis-response-table-cells/meals-analysis-response-graph-table-cell/meals-analysis-response-graph-table-cell-graph/utils/calculate-y-axis-value";

type PatientMealsSeries = {
  x: number;
  y: number;
};

export function buildPatientMealsSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[],
  startPlainDateTime: Temporal.PlainDateTime
): PatientMealsSeries[] {
  const patientMealsSeries: PatientMealsSeries[] = [];

  for (const patientMeal of patientMeals) {
    const dateTime = Temporal.PlainDateTime.from(
      `${patientMeal.date}T${patientMeal.time}`
    );

    const x = dateTime.since(startPlainDateTime).total({ unit: "minutes" });
    const y = calculateYaxisValue(
      patientCGMEntryDTOs,
      startPlainDateTime.toString(),
      new Date(patientMeal.date)
    );

    patientMealsSeries.push({ x, y });
  }

  return patientMealsSeries;
}
