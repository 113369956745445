import styled from "styled-components/macro";
import {
  lightShadow,
  transition,
} from "../../../../../../../../styles/global-style";
import { flexCenterCenter } from "../../../../../../../../styles/classes/gloabl-classes";

export const NutritionSummaryTooltipContainer = styled.div`
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  transition: ${transition};
  width: 277px;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.z300};
  background: ${({ theme }) => theme.colors.containerColor};
  font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  left: -75px;
  top: -230px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  ${lightShadow}

  p {
    margin: 0;
    padding-bottom: 0.4rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.titleColor};
  }

  ::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid ${({ theme }) => theme.colors.containerColor};
    position: absolute;
    bottom: -20px;
    left: 40%;
    transition: ${transition};
  }
`;

export const NutritionSummaryToolTipText = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
  color: ${({ theme }) => theme.colors.titleColor};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};

  span {
    padding: 0.1rem 0.5rem;
    width: 30%;
    text-align: right;
  }
`;

export const NutritionSummaryTooltipIcon = styled.i`
  font-size: 18px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const NutritionSummaryTooltipWrapper = styled.span`
  width: 4px;
  ${flexCenterCenter}

  ${NutritionSummaryTooltipContainer} {
    width: 92%;
    top: 0px;
    left: 0px;
    right: 0px;

    div {
      border: 0px;
    }

    ::after {
      display: none;
    }
  }

  ${NutritionSummaryToolTipText} {
    width: 90%;
    margin: 0 auto;

    p {
      text-align: left;
      max-width: 60%;
    }
  }

  :hover ${NutritionSummaryTooltipContainer} {
    visibility: visible;
    overflow: visible;
    opacity: 1;
  }
`;
