import { Modal } from "../../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../../components/modal/use-modal-helpers";
import { PatientDTO } from "../../../../../../../../models/patient-dtos/patient-dto";
import { PatientMealResponseDTO } from "../../../../../../../../models/patient-meal-response-dtos/patient-meal-response-dto";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import { MealsAnalysisResponseTableCompareModalBody } from "./meals-analysis-response-table-compare-modal-body/meals-analysis-response-table-compare-modal-body";

type MealsAnalysisResponseTableCompareModalProps = {
  patientDTO: PatientDTO;
  patientMealResponseDTOs: PatientMealResponseDTO[];
};

export const MealsAnalysisResponseTableCompareModal = (
  props: MealsAnalysisResponseTableCompareModalProps
) => {
  const { patientDTO, patientMealResponseDTOs } = props;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <>
      <Button type="button" onClick={openModal}>
        Compare
      </Button>
      <Modal showModal={isModalOpen} handleHideModal={closeModal}>
        <MealsAnalysisResponseTableCompareModalBody
          key={modalBodyKey}
          closeModal={closeModal}
          patientMealResponseDTOs={patientMealResponseDTOs}
          patientDTO={patientDTO}
        />
      </Modal>
    </>
  );
};
