import { CompareRight } from "./compare-right/compare-right";
import { CompareLeft } from "./compare-left/compare-left";
import { CompareContainer } from "./styled-compare";
import { CompareDateRangeParamsProvider } from "./context/compare-date-range-search-params-context";
import { CompareDateRangeProvider } from "./context/compare-date-context";

export const Compare = () => {
  return (
    <CompareContainer>
      <CompareDateRangeParamsProvider>
        <CompareDateRangeProvider>
          <CompareLeft />
          <CompareRight />
        </CompareDateRangeProvider>
      </CompareDateRangeParamsProvider>
    </CompareContainer>
  );
};
