import { DateRangeWrapper } from "./date-range-wrapper";

export type DateRangeDropdownState =
  | "1 Week"
  | "2 Weeks"
  | "30 Days"
  | "90 Days"
  | "Date Range";

export type DateRangeDropdownOptions = {
  optionId: number;
  value: DateRangeDropdownState;
  handlerValue?: 15 | 31 | 91;
};

export type DateRangeProps = {
  showResetDatesButton?: boolean;
  initialState?: DateRangeDropdownState;
  customDropdownOptions?: DateRangeDropdownOptions[];
  width?: number;
};

export const DateRange = (props: DateRangeProps) => {
  const {
    customDropdownOptions,
    initialState,
    showResetDatesButton,
    width,
  } = props;

  return (
    <DateRangeWrapper
      customDropdownOptions={customDropdownOptions}
      initialState={initialState}
      showResetDatesButton={showResetDatesButton}
      width={width}
    />
  );
};
