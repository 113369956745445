import { CompareDateRangeProvider } from "../context/compare-date-context";
import { CompareRightResponse } from "./compare-right-response/compare-right-response";
import { CompareRightContainer } from "./styled-compare-right";

export const CompareRight = () => {
  return (
    <CompareRightContainer>
      <CompareDateRangeProvider>
        <CompareRightResponse />
      </CompareDateRangeProvider>
    </CompareRightContainer>
  );
};
