import { LoadedCarbCounterFoodLoggingSelectedPatientMealFoodGroups } from "./carb-counter-food-logging-selected-patient-meal-food-groups/loaded-carb-counter-food-logging-selected-patient-meal-food-groups";
import { CarbCounterFoodLoggingSelectedPatientMealDetailsContainer } from "./styled-carb-counter-food-logging-selected-patient-meal-details";
import { LoadableDataState } from "../../../../../../hooks/use-loadable-data";
import { CarbCounterV1MealSummaryDTO } from "../../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";
import { OtidaLoader } from "../../../../../../components/loaders/otida-loader/otida-loader";
import { LoadableDataLoading } from "../../../../../../components/loadable-data-loading";
import { MacrosBar } from "../../../../../../components/macros-bar/macros-bar";

export type LoadableCarbCounterMealMacrosState = LoadableDataState<{
  carbCounterV1MealSummaryDTO: CarbCounterV1MealSummaryDTO;
}>;

export type CarbCounterMealDetailsState =
  | { kind: "Empty" }
  | { kind: "Loading" }
  | {
      kind: "Loaded";
      loadableCarbCounterMealMacrosState: LoadableCarbCounterMealMacrosState;
    };

type CarbCounterFoodLoggingSelectedPatientMealDetailsProps = {
  carbCounterMealDetailsState: CarbCounterMealDetailsState;
};

export const CarbCounterFoodLoggingSelectedPatientMealDetails = (
  props: CarbCounterFoodLoggingSelectedPatientMealDetailsProps
) => {
  const { carbCounterMealDetailsState } = props;

  if (carbCounterMealDetailsState.kind === "Loading") {
    return (
      <CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
        <OtidaLoader />
      </CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
    );
  }

  if (carbCounterMealDetailsState.kind === "Loaded") {
    return (
      <CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
        <LoadableDataLoading
          state={carbCounterMealDetailsState.loadableCarbCounterMealMacrosState}
          successComponent={(carbCounterV1MealSummaryDTO) => (
            <>
              <MacrosBar
                totalKCals={
                  carbCounterV1MealSummaryDTO.carbCounterV1MealSummaryDTO
                    .totalKcal
                }
                carbsPercentage={
                  carbCounterV1MealSummaryDTO.carbCounterV1MealSummaryDTO
                    .carbsKcalPercentage
                }
                fatsPercentage={
                  carbCounterV1MealSummaryDTO.carbCounterV1MealSummaryDTO
                    .fatKcalPercentage
                }
                proteinPercentage={
                  carbCounterV1MealSummaryDTO.carbCounterV1MealSummaryDTO
                    .proteinKcalPercentage
                }
                width="70%"
                displayGrams={{
                  displayOnlyGrams: true,
                  carbsGm:
                    carbCounterV1MealSummaryDTO.carbCounterV1MealSummaryDTO
                      .totalCarbsGm,
                  fatsGm:
                    carbCounterV1MealSummaryDTO.carbCounterV1MealSummaryDTO
                      .totalFatGm,
                  proteinGm:
                    carbCounterV1MealSummaryDTO.carbCounterV1MealSummaryDTO
                      .totalProteinGm,
                }}
              />
              <LoadedCarbCounterFoodLoggingSelectedPatientMealFoodGroups
                carbCounterV1MealSummaryDTO={
                  carbCounterV1MealSummaryDTO.carbCounterV1MealSummaryDTO
                }
              />
            </>
          )}
        />
      </CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
    );
  }

  return (
    <CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
      <MacrosBar
        totalKCals={0}
        carbsPercentage={0}
        fatsPercentage={0}
        proteinPercentage={0}
        width="50%"
        displayGrams={{
          displayOnlyGrams: true,
          carbsGm: 0,
          fatsGm: 0,
          proteinGm: 0,
        }}
      />
    </CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
  );
};
