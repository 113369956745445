import styled from "styled-components/macro";

export const CircleChartContainer = styled.div<{
  smallCircles: boolean;
  viewPlanChart?: boolean;
}>`
  display: flex;
  flex-direction: ${({ viewPlanChart }) => (viewPlanChart ? "row" : "column")};
  justify-content: ${({ viewPlanChart }) =>
    viewPlanChart ? "flex-start" : "center"};
  gap: ${({ viewPlanChart }) => viewPlanChart && "0px 8px"};
  align-items: center;
  margin: ${({ smallCircles }) => (smallCircles ? "0rem 0rem" : "0rem 0.5rem")};
  border: ${({ viewPlanChart, theme }) =>
    viewPlanChart ? `1px solid ${theme.colors.borderColor}` : `0px`};
  flex: ${({ viewPlanChart }) => viewPlanChart && "1"};
  padding: ${({ viewPlanChart }) => (viewPlanChart ? "8px" : "0px")};
  border-radius: ${({ viewPlanChart }) => (viewPlanChart ? "8px" : "0px")};
`;

export const CircleChart = styled.div<{
  chartProgress: number;
  isBad: boolean;
  smallCircles: boolean;
  viewPlanChart?: boolean;
}>`
  position: relative;
  height: ${({ smallCircles, viewPlanChart }) =>
    smallCircles ? "30px" : viewPlanChart ? "35px" : "40px"};
  width: ${({ smallCircles, viewPlanChart }) =>
    smallCircles ? "30px" : viewPlanChart ? "35px" : "40px"};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: conic-gradient(
    ${({ theme, isBad }) =>
        isBad ? theme.colors.danger : theme.colors.success}
      ${({ chartProgress }) => `${chartProgress}%`},
    #fff6fa 0deg
  );

  ::before {
    content: "";
    position: absolute;
    height: ${({ smallCircles, viewPlanChart }) =>
      smallCircles ? "25px" : viewPlanChart ? "31px" : "30px"};
    width: ${({ smallCircles, viewPlanChart }) =>
      smallCircles ? "25px" : viewPlanChart ? "31px" : "30px"};
    border-radius: 50%;
    background: #fff;
  }
`;

export const ChartNumber = styled.span<{
  chartProgress: number;
  smallCircles: boolean;
  viewPlanChart?: boolean;
}>`
  position: relative;
  font-size: ${({ theme, smallCircles, viewPlanChart }) =>
    smallCircles
      ? "8px"
      : viewPlanChart
      ? "10px"
      : theme.typography.fontSize.xsFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const ChartText = styled.p<{
  smallCircles: boolean;
  viewPlanChart?: boolean;
}>`
  font-size: ${({ theme, smallCircles, viewPlanChart }) =>
    smallCircles
      ? "8px"
      : viewPlanChart
      ? "10px"
      : theme.typography.fontSize.xsFontSize};
  font-weight: ${({ theme, smallCircles }) =>
    smallCircles
      ? theme.typography.fontWeight.bold
      : theme.typography.fontWeight.regular};
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0;
`;
