import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import { foodGroups, staticFoodGroups } from "../utils/food-groups-utils";
import { PatientMealDTO } from "../../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import {
  NutritionSummaryTooltipIcon,
  NutritionSummaryTooltipWrapper,
} from "../nutrition-summary-tooltip/styled-nutrition-summary-tooltip";
import { NutritionSummaryTooltip } from "../nutrition-summary-tooltip/nutrition-summary-tooltip";
import {
  NutritionSummaryCardCenter,
  NutritionSummaryCardCenterList,
  NutritionSummaryCardCenterListItem,
} from "./styled-nutrition-summary-center";

export type NutritionSummaryCenterProps = {
  patientMealDTOs: PatientMealDTO[];
};

export function NutritionSummaryCenter(props: NutritionSummaryCenterProps) {
  const { patientMealDTOs } = props;

  const nutritionSummaryServings = foodGroups(patientMealDTOs);

  const categoriesWithServings = nutritionSummaryServings.map(
    (nutritionSummaryServing) => (
      <NutritionSummaryCardCenterListItem key={nutritionSummaryServing.id}>
        <span>{roundTo1DecimalPlace(nutritionSummaryServing.value)}</span>
        <p>
          {nutritionSummaryServing.title === "Caffeinated Beverages"
            ? "Caffeinated Bev."
            : nutritionSummaryServing.title}
        </p>
        {(nutritionSummaryServing.title === "Meats" ||
          nutritionSummaryServing.title === "Dairy" ||
          nutritionSummaryServing.title === "Oils & Fats") && (
          <NutritionSummaryTooltipWrapper>
            <NutritionSummaryTooltipIcon className="material-symbols-outlined">
              info
            </NutritionSummaryTooltipIcon>
            <NutritionSummaryTooltip
              tooltip={{
                title: nutritionSummaryServing.tooltip?.tooltipTitle,
                items: nutritionSummaryServing.tooltip?.items,
              }}
            />
          </NutritionSummaryTooltipWrapper>
        )}
      </NutritionSummaryCardCenterListItem>
    )
  );

  const left =
    categoriesWithServings.length > 0
      ? categoriesWithServings.slice(0, 4)
      : staticFoodGroups.slice(0, 4).map((item) => (
          <NutritionSummaryCardCenterListItem key={item.id}>
            <span>{item.value}</span>
            <p>{item.title}</p>
          </NutritionSummaryCardCenterListItem>
        ));

  const right =
    categoriesWithServings.length > 0
      ? categoriesWithServings.slice(4, 8)
      : staticFoodGroups.slice(4, 8).map((item) => (
          <NutritionSummaryCardCenterListItem key={item.id}>
            <span>{item.value}</span>
            <p>{item.title}</p>
          </NutritionSummaryCardCenterListItem>
        ));

  return (
    <NutritionSummaryCardCenter>
      <NutritionSummaryCardCenterList>
        <div>{left}</div>
        <div>{right}</div>
      </NutritionSummaryCardCenterList>
    </NutritionSummaryCardCenter>
  );
}
