import {
  NutritionSummaryContainer,
  NutritionSummaryCardHeader,
} from "./styled-nutrition-summary";
import { PatientMealDTO } from "../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { NutritionSummaryFooter } from "./nutrition-summary-footer/nutrition-summary-footer";
import { NutritionSummaryCenter } from "./nutrition-summary-center/nutrition-summary-center";

export type NutritionSummaryProps = {
  patientMealDTOs: PatientMealDTO[];
};

export const NutritionSummary = (props: NutritionSummaryProps) => {
  const { patientMealDTOs } = props;

  return (
    <NutritionSummaryContainer>
      <NutritionSummaryCardHeader>
        <h3>Nutrition summary</h3>
        <p>Food groups</p>
      </NutritionSummaryCardHeader>
      <NutritionSummaryCenter patientMealDTOs={patientMealDTOs} />
      <NutritionSummaryFooter patientMealDTOs={patientMealDTOs} />
    </NutritionSummaryContainer>
  );
};
