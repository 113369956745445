import { Temporal } from "temporal-polyfill";
import {
  DateRangeButton,
  DateRangeButtonsContainer,
  DateRangeSelectedDatesContainer,
  DateRangeSelectedDatesText,
} from "../../../../../components/date-range/date-range-selected-dates/styled-date-range-selected-dates";
import { SelectedDates } from "../context/compare-date-context";

type CompareDateRangeSelectedDatesProps = {
  handleIncrementDates: () => void;
  handleDecrementDates: () => void;
  selectedDates: SelectedDates;
};

export const CompareDateRangeSelectedDates = (
  props: CompareDateRangeSelectedDatesProps
) => {
  const { handleIncrementDates, handleDecrementDates, selectedDates } = props;

  const selectedStartDate = Temporal.PlainDate.from(
    selectedDates.temporalStartDate
  );

  const selectedEndDate = Temporal.PlainDate.from(
    selectedDates.temporalEndDate
  );

  const startDate = selectedStartDate.toLocaleString("en-AU", {
    month:
      selectedStartDate.month === selectedEndDate.month ? undefined : "short",
    day: "numeric",
    year: undefined,
  });

  const endDate = selectedEndDate.toLocaleString("en-AU", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <DateRangeSelectedDatesContainer>
      <DateRangeButtonsContainer>
        <DateRangeButton type="button" onClick={handleDecrementDates}>
          <span className="material-symbols-outlined">arrow_back_ios</span>
        </DateRangeButton>
        <DateRangeButton type="button" onClick={handleIncrementDates}>
          <span className="material-symbols-outlined">arrow_forward_ios</span>
        </DateRangeButton>
      </DateRangeButtonsContainer>
      <DateRangeSelectedDatesText>
        {startDate} - {endDate}
        <span>{selectedDates.count} Days</span>
      </DateRangeSelectedDatesText>
    </DateRangeSelectedDatesContainer>
  );
};
