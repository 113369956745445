import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import {
  NutritionSummaryTooltipContainer,
  NutritionSummaryToolTipText,
} from "./styled-nutrition-summary-tooltip";

type NutritionSummaryTooltipItem = {
  title: string;
  value: number;
};

type NutritionSummaryTooltipProps = {
  tooltip: {
    title?: string;
    items?: NutritionSummaryTooltipItem[];
  };
};

export const NutritionSummaryTooltip = (
  props: NutritionSummaryTooltipProps
) => {
  const { tooltip } = props;

  return (
    <NutritionSummaryTooltipContainer>
      <p>{tooltip?.title}</p>
      {tooltip.items?.map((item, index) => (
        <NutritionSummaryToolTipText key={index}>
          <p>
            {item.title.includes("Oils")
              ? item.title.slice(15, item.title.length).replace(")", "")
              : item.title}
          </p>
          <span>{roundTo1DecimalPlace(item.value)}</span>
        </NutritionSummaryToolTipText>
      ))}
    </NutritionSummaryTooltipContainer>
  );
};
