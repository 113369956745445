import styled from "styled-components/macro";

export const DailyFixedSummaryChartContainerContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 4px 16px;
  display: flex;
  gap: 0rem 12px;

  @media screen and (max-width: 1440px) {
    padding: 8px calc(27px - 3px);
  }

  @media screen and (max-width: 940px) {
    width: 100%;
    overflow: auto;
  }

  @media screen and (max-width: 375px) {
    padding: 8px 0px;
  }
`;

export const DailyFixedSummaryChartChart = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  width: 70%;
  border-radius: 12px;
`;
