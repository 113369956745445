import { MacrosBar } from "../../../../../components/macros-bar/macros-bar";
import { useNutritionGolasLastFoodGroupSelected } from "../../nutrition-goals/context/nutrition-goals-last-food-group-selected-context";
import { calculateNutritionGoalsMacros } from "../../nutrition-goals/utils/nutrition-goals-utils";
import { DietitianReportMacrosBarContainer } from "./styled-dietitian-report-macros-bar";

export const DietitianReportMacrosBar = () => {
  const { lastFoodGroupSelected } = useNutritionGolasLastFoodGroupSelected();

  const {
    carbsKCalsGrams,
    carbsPercentage,
    fatKCalsGrams,
    fatPercentage,
    kCals,
    proteinKCalsGrams,
    proteinPercentage,
  } = calculateNutritionGoalsMacros(lastFoodGroupSelected);

  return (
    <DietitianReportMacrosBarContainer>
      <MacrosBar
        totalKCals={kCals}
        carbsPercentage={carbsPercentage}
        fatsPercentage={fatPercentage}
        proteinPercentage={proteinPercentage}
        displayGrams={{
          displayOnlyGrams: false,
          carbsGm: carbsKCalsGrams,
          fatsGm: fatKCalsGrams,
          proteinGm: proteinKCalsGrams,
        }}
      />
    </DietitianReportMacrosBarContainer>
  );
};
