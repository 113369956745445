import { PatientDTO } from "../../../../../../../models/patient-dtos/patient-dto";
import { PatientMealResponseDTO } from "../../../../../../../models/patient-meal-response-dtos/patient-meal-response-dto";
import { useMealsAnalysisSelectedTableRows } from "../../context/meals-analysis-selected-table-rows-context";
import { MealsAnalysisResponseTableCompareModal } from "./meals-analysis-response-table-compare-modal/meals-analysis-response-table-compare-modal";
import {
  MealsAnalysisResponseTableCompareContainer,
  MealsAnalysisResponseTableCompareText,
} from "./styled-meals-analysis-response-table-compare";

type MealsAnalysisResponseTableCompareProps = {
  patientDTO: PatientDTO;
  patientMealResponseDTOs: PatientMealResponseDTO[];
};

export const MealsAnalysisResponseTableCompare = (
  props: MealsAnalysisResponseTableCompareProps
) => {
  const { patientDTO, patientMealResponseDTOs } = props;

  const {
    selectedTableRows,
    setSelectedTableRows,
  } = useMealsAnalysisSelectedTableRows();

  return (
    <MealsAnalysisResponseTableCompareContainer
      $show={selectedTableRows.length > 0}
    >
      <MealsAnalysisResponseTableCompareText>
        <p>{selectedTableRows.length} selected</p>
      </MealsAnalysisResponseTableCompareText>
      <MealsAnalysisResponseTableCompareModal
        patientDTO={patientDTO}
        patientMealResponseDTOs={patientMealResponseDTOs}
      />
      <span
        className="material-symbols-outlined"
        onClick={() => setSelectedTableRows([])}
      >
        close
      </span>
    </MealsAnalysisResponseTableCompareContainer>
  );
};
