import { useParams } from "react-router-dom";
import {
  LoadedCompareLeftProvider,
  LoadedLeftCompare,
} from "../context/loaded-compare-left-context";
import { useGetPatient } from "../../../../../../hooks/patient-hooks/use-get-patient";
import { useGetPatientCGMEntries } from "../../../../../../hooks/use-get-patient-cgm-entries";
import { useCallback } from "react";
import { Loading } from "../../../../../../components/loading";
import { CompareLeftResponseHeader } from "./compare-left-response-header";
import { CompareLeftResponseStatistics } from "./compare-left-response-statistics";
import { CompareLeftTimeRanges } from "./compare-left-time-ranges/compare-left-time-ranges";
import { CompareLeftAgpProvider } from "../context/compare-left-agp-context";
import { CompareLeftResponseAgp } from "./compare-left-response-agp/compare-left-response-agp";
import { useCompareDateRange } from "../../context/compare-date-context";

export const CompareLeftResponse = () => {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatient = useGetPatient();
  const getPatientCGMEntries = useGetPatientCGMEntries();

  const { temporalStartDate, temporalEndDate } = useCompareDateRange();

  const compareLeftResponseLoadCallback = useCallback(async (): Promise<
    LoadedLeftCompare
  > => {
    const patientDTO = await getPatient(patientID);

    const cgmSource = "Libreview";
    const patientCGMEntryDTOs = await getPatientCGMEntries(
      patientID,
      cgmSource,
      temporalStartDate.left,
      temporalEndDate.left
    );

    return {
      patientDTO,
      patientCGMEntryDTOs,
    };
  }, [
    patientID,
    getPatient,
    getPatientCGMEntries,
    temporalStartDate,
    temporalEndDate,
  ]);

  return (
    <Loading
      load={compareLeftResponseLoadCallback}
      successComponent={(data) => (
        <LoadedCompareLeftProvider
          patientDTO={data.patientDTO}
          patientCGMEntryDTOs={data.patientCGMEntryDTOs}
        >
          <CompareLeftResponseHeader />
          <CompareLeftResponseStatistics />
          <CompareLeftTimeRanges />
          <CompareLeftAgpProvider>
            <CompareLeftResponseAgp />
          </CompareLeftAgpProvider>
        </LoadedCompareLeftProvider>
      )}
    />
  );
};
