import { Temporal } from "temporal-polyfill";
import { PatientCGMEntryDTO } from "../../../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { GAP_THRESHOLD_MINUTES } from "../../../../../../../../../../../../constants/config";
import { CgmTooltipProps } from "../../../../../../../../../../../../components/cgm-syncfusion-chart/utils/cgm-tooltip/cgm-tooltip";
import { isGlucosePointInRange } from "../../../../../../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/is-glucose-point-in-range";
import { PatientDTO } from "../../../../../../../../../../../../models/patient-dtos/patient-dto";

type CGMEntriesReadingsSeries = {
  x: number;
  y?: number;
  tooltip?: CgmTooltipProps;
};

export function buildCGMEntriesReadingsSeries(
  patientDTO: PatientDTO,
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  startPlainDateTime: Temporal.PlainDateTime
): CGMEntriesReadingsSeries[] {
  const cgmEntriesReadingsSeries: CGMEntriesReadingsSeries[] = [];

  let lastPlainDateTime: Temporal.PlainDateTime | undefined = undefined;

  for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
    if (patientCGMEntryDTO.source === "Libreview") {
      const currentPlainDateTime = Temporal.PlainDateTime.from(
        `${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`
      );

      const x = currentPlainDateTime
        .since(startPlainDateTime)
        .total({ unit: "minutes" });
      const y = patientCGMEntryDTO.glucoseMGPerDL;

      if (
        lastPlainDateTime !== undefined &&
        Temporal.PlainDateTime.compare(
          lastPlainDateTime.add({ minutes: GAP_THRESHOLD_MINUTES }),
          currentPlainDateTime
        ) === -1
      ) {
        cgmEntriesReadingsSeries.push({ x });
      }

      const tooltip: CgmTooltipProps = {
        x: new Date(`${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`),
        y: patientCGMEntryDTO.glucoseMGPerDL,
        kind: {
          kind: "Reading",
          data: {
            ...patientCGMEntryDTO,
            isInRange: isGlucosePointInRange(patientDTO.type, {
              glucose: patientCGMEntryDTO.glucoseMGPerDL,
              source: patientCGMEntryDTO.source,
              time: Temporal.PlainTime.from(patientCGMEntryDTO.time),
              tag: patientCGMEntryDTO.tag,
            })!,
          },
        },
      };

      cgmEntriesReadingsSeries.push({
        x,
        y,
        tooltip,
      });

      lastPlainDateTime = currentPlainDateTime;
    }
  }

  return cgmEntriesReadingsSeries;
}
