import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumFont,
  mediumSemiBoldFont,
} from "../../../../../../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../../../../../../styles/global-style";

export const MealsAnalysisResponseTableCompareMealsDropdownWrapper = styled.div<{
  $showDropdown: boolean;
}>`
  height: ${({ $showDropdown }) => ($showDropdown ? "auto" : "0px")};
  overflow: ${({ $showDropdown }) => ($showDropdown ? "visible" : "hidden")};
  opacity: ${({ $showDropdown }) => ($showDropdown ? "1" : "0")};
  transition: ${transition};
`;

export const MealsAnalysisResponseTableCompareMealsDropdownTitle = styled.p<{
  $showDropdown?: boolean;
}>`
  ${flexCenterSpaceBetween}
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.buttonColorAlt};
  margin: 8px;
  user-select: none;

  :first-child {
    cursor: pointer;
  }

  span {
    rotate: ${({ $showDropdown }) => ($showDropdown ? "-90deg" : "90deg")};
    transition: ${transition};
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const MealsAnalysisResponseTableCompareMealsDropdownItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 8px;

  p {
    ${mediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;

  }

  span {
    ${mediumSemiBoldFont}
    color: ${({ theme }) => theme.newColors.textColorAlt};
  }
`;
