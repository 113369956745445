import { PropsWithChildren } from "react";
import { MealsAnalysisResponseTagsProvider } from "./meals-analysis-response-tags-context";
import { CarbsourcesFilterProvider } from "./meals-analysis-response-carbsources-filter-context";
import { MealsAnalysisSelectedTableRowsProvider } from "./meals-analysis-selected-table-rows-context";
import { DateRangeProviders } from "../../../../../../components/date-range/context/date-range-providers";

type MealsAnalysisResponseProvidersProps = PropsWithChildren<{}>;

export const MealsAnalysisResponseProviders = (
  props: MealsAnalysisResponseProvidersProps
) => {
  const { children } = props;

  return (
    <DateRangeProviders startWithUndefined={false}>
      <MealsAnalysisResponseTagsProvider>
        <CarbsourcesFilterProvider>
          <MealsAnalysisSelectedTableRowsProvider>
            {children}
          </MealsAnalysisSelectedTableRowsProvider>
        </CarbsourcesFilterProvider>
      </MealsAnalysisResponseTagsProvider>
    </DateRangeProviders>
  );
};
