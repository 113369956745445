import { CompareDateRange } from "../../compare-date-range/compare-date-range";
import { useCompareDateRange } from "../../context/compare-date-context";

export const CompareLeftResponseHeader = () => {
  const {
    temporalStartDate,
    temporalEndDate,
    selectedDates,
    onDatesChange,
    handleDecrementDates,
    handleIncrementDates,
  } = useCompareDateRange();

  return (
    <CompareDateRange
      temporalStartDate={temporalStartDate.left}
      temporalEndDate={temporalEndDate.left}
      onDatesChange={onDatesChange}
      handleIncrementDates={() => handleIncrementDates("Left")}
      handleDecrementDates={() => handleDecrementDates("Left")}
      selectedDates={selectedDates.left}
      compareSide="Left"
    />
  );
};
