import { CompareLeftResponse } from "./compare-left-response/compare-left-response";
import { CompareLeftContainer } from "./styled-compare-left";

export const CompareLeft = () => {
  return (
    <CompareLeftContainer>
      <CompareLeftResponse />
    </CompareLeftContainer>
  );
};
