import React from "react";
import { DateRangeInputPickerInputContainer } from "../../../../../components/date-range/date-range-input-picker/styled-date-range-input-picker";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useCompareDateRange } from "../context/compare-date-context";

type CompareDateRangeInputPickerProps = {
  temporalStartDate: string;
  temporalEndDate: string;
  onDatesChange: (e: any) => void;
};

export const CompareDateRangeInputPicker = (
  props: CompareDateRangeInputPickerProps
) => {
  const { temporalStartDate, temporalEndDate, onDatesChange } = props;
  const { dateRangePickerRef } = useCompareDateRange();

  return (
    <DateRangeInputPickerInputContainer>
      <DateRangePickerComponent
        startDate={new Date(temporalStartDate)}
        endDate={new Date(temporalEndDate)}
        placeholder="Select date range"
        format="dd/ M /yyyy"
        separator="-"
        onChange={onDatesChange}
        ref={dateRangePickerRef}
        onClick={() => dateRangePickerRef.current?.show()}
      />
    </DateRangeInputPickerInputContainer>
  );
};
