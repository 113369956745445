import { CgmGoogleChart } from "../../../../../../components/cgm-google-chart/cgm-google-chart";
import { DayAggregate } from "../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { DailyChartRanges } from "../../../../bgl-analysis/daily-analysis/daily-charts/daily-chart/daily-chart-ranges/daily-chart-ranges";
import { DailyChartRangesContainer } from "../../../../bgl-analysis/daily-analysis/daily-charts/daily-chart/styled-daily-chart";
import { useDetailedPlan } from "../../../context/loadable-detailed-plan-context";
import {
  GlucoseChartContainer,
  GlucoseChartContainerHeader,
  GlucoseChartsCard,
  GlucoseChartsCardHeader,
} from "../feedback-modal/blood-glucose-modal-body/blood-glucose-charts/styled-blood-glucose-charts";

type ListBloodGlucoseChartProps = {
  dayAggregate: DayAggregate;
  viewPlanChart: boolean;
};

export const ListBloodGlucoseChart = (props: ListBloodGlucoseChartProps) => {
  const { dayAggregate, viewPlanChart } = props;

  const { patientDTO } = useDetailedPlan();

  const chartAreaOptions = {
    left: 25,
    top: 20,
    bottom: 50,
    right: 15,
  };

  const cgmReadings = dayAggregate.readings.filter(
    (reading) => reading.source === "Libreview"
  );

  return (
    <GlucoseChartsCard viewPlanChart={viewPlanChart}>
      <GlucoseChartsCardHeader>
        <p>
          {viewPlanChart ? (
            "Reading: "
          ) : (
            <span className="material-symbols-outlined">calendar_today</span>
          )}
          {dayAggregate.date
            .toLocaleString("en-US", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            })
            .toString()}
        </p>
      </GlucoseChartsCardHeader>
      <GlucoseChartContainer viewPlanChart={viewPlanChart}>
        <GlucoseChartContainerHeader viewPlanChart={viewPlanChart}>
          <DailyChartRangesContainer viewPlanChart={viewPlanChart}>
            {cgmReadings.length > 0 && (
              <DailyChartRanges
                smallCircles={viewPlanChart ? false : true}
                viewPlanChart={viewPlanChart}
                dayAggregate={dayAggregate}
                patientDTO={patientDTO}
              />
            )}
          </DailyChartRangesContainer>
        </GlucoseChartContainerHeader>
        <CgmGoogleChart
          patientDTO={patientDTO}
          dayAggregate={dayAggregate}
          chartAreaOptionsProp={chartAreaOptions}
          feedbackHeight={130}
        />
      </GlucoseChartContainer>
    </GlucoseChartsCard>
  );
};
