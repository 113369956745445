import { roundTo1DecimalPlace } from "../../utils/math-utils";
import {
  Carbs,
  Fat,
  MacrosBarBody,
  MacrosBarContainer,
  MacrosBarFooter,
  MacrosBarHeader,
  Protien,
} from "./styled-macros-bar";

type MacrosBarProps = {
  totalKCals: number;
  carbsPercentage: number;
  fatsPercentage: number;
  proteinPercentage: number;
  width?: string;
  height?: string;
  displayGrams?: {
    displayOnlyGrams: boolean;
    carbsGm: number;
    fatsGm: number;
    proteinGm: number;
  };
};

export const MacrosBar = (props: MacrosBarProps) => {
  const {
    width,
    height,
    totalKCals,
    carbsPercentage,
    fatsPercentage,
    proteinPercentage,
    displayGrams,
  } = props;

  const isGramsDefined = displayGrams !== undefined;

  const macros =
    isGramsDefined && !displayGrams.displayOnlyGrams
      ? {
          carbs: `${roundTo1DecimalPlace(
            displayGrams.carbsGm
          )}gm (${roundTo1DecimalPlace(carbsPercentage)}%)`,
          fats: `${roundTo1DecimalPlace(
            displayGrams.fatsGm
          )}gm (${roundTo1DecimalPlace(fatsPercentage)}%)`,
          protein: `${roundTo1DecimalPlace(
            displayGrams.proteinGm
          )}gm (${roundTo1DecimalPlace(proteinPercentage)}%)`,
        }
      : isGramsDefined && displayGrams.displayOnlyGrams
      ? {
          carbs: `${roundTo1DecimalPlace(displayGrams.carbsGm)}gm`,
          fats: `${roundTo1DecimalPlace(displayGrams.fatsGm)}gm`,
          protein: `${roundTo1DecimalPlace(displayGrams.proteinGm)}gm`,
        }
      : {
          carbs: `${roundTo1DecimalPlace(carbsPercentage)}%`,
          fats: `${roundTo1DecimalPlace(fatsPercentage)}%`,
          protein: `${roundTo1DecimalPlace(proteinPercentage)}%`,
        };

  return (
    <MacrosBarContainer $width={width} $height={height}>
      <MacrosBarHeader>
        <p>Macronutrients</p>
        <span>{roundTo1DecimalPlace(totalKCals)} Kcal</span>
      </MacrosBarHeader>
      <MacrosBarBody style={{ overflow: "hidden" }}>
        <Carbs width={`${carbsPercentage}%`} />
        <Fat width={`${carbsPercentage + fatsPercentage}%`} />
        <Protien
          width={`${carbsPercentage + fatsPercentage + proteinPercentage}%`}
        />
      </MacrosBarBody>
      <MacrosBarFooter>
        <div>
          <div />
          <span>
            Carbs: {macros.carbs.split("(")[0]}{" "}
            <span>{macros.carbs.split(" ")[1]}</span>{" "}
          </span>
        </div>
        <div>
          <div />
          <span>
            Fat: {macros.fats.split("(")[0]}
            <span>{macros.fats.split(" ")[1]}</span>
          </span>
        </div>
        <div>
          <div />
          <span>
            Protien: {macros.protein.split("(")[0]}
            <span>{macros.protein.split(" ")[1]}</span>
          </span>
        </div>
      </MacrosBarFooter>
    </MacrosBarContainer>
  );
};
