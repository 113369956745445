import { useMemo } from "react";
import { PatientMealDTO } from "../../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { MacrosBar } from "../../../../../../../../components/macros-bar/macros-bar";

export type NutritionSummaryFooterProps = {
  patientMealDTOs: PatientMealDTO[];
};

export function NutritionSummaryFooter(props: NutritionSummaryFooterProps) {
  const { patientMealDTOs } = props;

  const { totalKCals } = useMemo(() => {
    let totalKCals = 0;

    for (const patientMealDTO of patientMealDTOs) {
      const carbCounterMealSummary = patientMealDTO.carbCounterV1MealSummary;

      if (carbCounterMealSummary) {
        totalKCals += carbCounterMealSummary.totalKcal;
      }
    }

    return { totalKCals };
  }, [patientMealDTOs]);

  const { carbsPercentage } = useMemo(() => {
    let carbsPercentage = 0;
    let totalCarbsKcal = 0;

    for (const patientMealDTO of patientMealDTOs) {
      const carbCounterMealSummary = patientMealDTO.carbCounterV1MealSummary;

      if (carbCounterMealSummary) {
        totalCarbsKcal += carbCounterMealSummary.totalCarbsKcal;
        carbsPercentage = (totalCarbsKcal / totalKCals) * 100;
      }
    }

    return { carbsPercentage };
  }, [patientMealDTOs, totalKCals]);

  const { fatsPercentage } = useMemo(() => {
    let fatsPercentage = 0;
    let totalFatsKcal = 0;

    for (const patientMealDTO of patientMealDTOs) {
      const carbCounterMealSummary = patientMealDTO.carbCounterV1MealSummary;

      if (carbCounterMealSummary) {
        totalFatsKcal += carbCounterMealSummary.totalFatKcal;
        fatsPercentage = (totalFatsKcal / totalKCals) * 100;
      }
    }

    return { fatsPercentage };
  }, [patientMealDTOs, totalKCals]);

  const { proteinPercentage } = useMemo(() => {
    let proteinPercentage = 0;
    let totalProteinKcal = 0;

    for (const patientMealDTO of patientMealDTOs) {
      const carbCounterMealSummary = patientMealDTO.carbCounterV1MealSummary;

      if (carbCounterMealSummary) {
        totalProteinKcal += carbCounterMealSummary.totalProteinKcal;
        proteinPercentage = (totalProteinKcal / totalKCals) * 100;
      }
    }

    return { proteinPercentage };
  }, [patientMealDTOs, totalKCals]);

  return (
    <MacrosBar
      totalKCals={totalKCals}
      carbsPercentage={carbsPercentage}
      fatsPercentage={fatsPercentage}
      proteinPercentage={proteinPercentage}
    />
  );
}
