import { useMemo } from "react";
import { ChartButton } from "../../feedback-modal-body/styled-feedback-modal-body";
import {
  GlucoseChartContainer,
  GlucoseChartContainerHeader,
  GlucoseChartsCard,
  GlucoseChartsCardHeader,
} from "./styled-blood-glucose-charts";
import { useFormContext } from "react-hook-form";
import { BloodGlucoseFormInputs } from "../blood-glucose-container";
import { CgmGoogleChart } from "../../../../../../../../components/cgm-google-chart/cgm-google-chart";
import { DayAggregate } from "../../../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { useDetailedPlan } from "../../../../../context/loadable-detailed-plan-context";
import { DailyChartRangesContainer } from "../../../../../../bgl-analysis/daily-analysis/daily-charts/daily-chart/styled-daily-chart";
import { DailyChartRanges } from "../../../../../../bgl-analysis/daily-analysis/daily-charts/daily-chart/daily-chart-ranges/daily-chart-ranges";

type BloodGlucoseChartProps = {
  dayAggregate: DayAggregate;
  hasButton: boolean;
};

export const BloodGlucoseChart = (props: BloodGlucoseChartProps) => {
  const { dayAggregate, hasButton } = props;

  const { patientDTO } = useDetailedPlan();

  const { watch, setValue } = useFormContext<BloodGlucoseFormInputs>();

  const bloodGlucoseAttachments = watch("attachments");

  const findAttachment = useMemo(() => {
    return bloodGlucoseAttachments.indexOf(
      bloodGlucoseAttachments.find(
        (item) => item.bloodGlucoseChartDate === dayAggregate.date.toString()
      )!
    );
  }, [bloodGlucoseAttachments, dayAggregate.date]);

  const handleToggleChart = () => {
    if (findAttachment > -1) {
      setValue(
        "attachments",
        bloodGlucoseAttachments.filter(
          (item) =>
            !(
              item.attachmentType === "BloodGlucoseChart" &&
              item.bloodGlucoseChartDate === dayAggregate.date.toString()
            )
        )
      );
    } else {
      setValue("attachments", [
        ...bloodGlucoseAttachments,
        {
          attachmentType: "BloodGlucoseChart",
          bloodGlucoseChartDate: dayAggregate.date.toString(),
        },
      ]);
    }
  };

  const chartAreaOptions = {
    left: 25,
    top: 20,
    bottom: 50,
    right: 15,
  };

  const cgmReadings = dayAggregate.readings.filter(
    (reading) => reading.source === "Libreview"
  );

  return (
    <GlucoseChartsCard viewPlanChart={false}>
      <GlucoseChartsCardHeader>
        <p>
          <span className="material-symbols-outlined">calendar_today</span>
          {dayAggregate.date
            .toLocaleString("en-US", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            })
            .toString()}
        </p>
        {hasButton && (
          <ChartButton
            disabled={
              bloodGlucoseAttachments.length >= 3 && findAttachment === -1
            }
            added={findAttachment > -1}
            type="button"
            onClick={handleToggleChart}
          >
            {findAttachment > -1 ? (
              <span className="material-symbols-outlined">done</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )}
          </ChartButton>
        )}
      </GlucoseChartsCardHeader>
      <GlucoseChartContainer>
        <GlucoseChartContainerHeader>
          <DailyChartRangesContainer>
            {cgmReadings.length > 0 && (
              <DailyChartRanges
                smallCircles
                dayAggregate={dayAggregate}
                patientDTO={patientDTO}
              />
            )}
          </DailyChartRangesContainer>
        </GlucoseChartContainerHeader>
        <CgmGoogleChart
          patientDTO={patientDTO}
          dayAggregate={dayAggregate}
          chartAreaOptionsProp={chartAreaOptions}
          feedbackHeight={130}
        />
      </GlucoseChartContainer>
    </GlucoseChartsCard>
  );
};
