import styled from "styled-components/macro";

export const MacrosBarContainer = styled.div<{
  $width?: string;
  $height?: string;
}>`
  padding: 8px 12px 0px;
  width: ${({ $width }) => ($width ? $width : "auto")};
  height: ${({ $height }) => ($height ? $height : "auto")};
`;

export const MacrosBarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.screenTitleColor};
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.newColors.modalContainerAlt};
    font-weight: 600;
  }
`;

export const MacrosBarBody = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 6px;
  background: ${({ theme }) => theme.newColors.otidaO2};
  margin: 8px 0px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Carbs = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.newColors.warningAlt};
  position: absolute;
  z-index: 3;
`;

export const Fat = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primaryColor};
  position: absolute;
  z-index: 2;
`;

export const Protien = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primaryColorAlt};
  position: absolute;
  z-index: 1;
`;

export const MacrosBarFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px 16px;
  margin-top: 16px;

  div {
    span {
      span {
        color: ${({ theme }) => theme.colors.textColor};
      }
    }
  }
`;
