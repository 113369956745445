import styled from "styled-components/macro";
import {
  cardDateFont,
  flexCenter,
} from "../../../../../../../styles/classes/gloabl-classes";

export const DailyFixedSummaryChartCardContainer = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  width: 30%;
`;

export const DailyFixedSummaryChartCardHeader = styled.div`
  ${flexCenter}
  gap: 0px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 8px 16px;
  height: 70px;

  p {
    ${cardDateFont};
    margin: 0px;
  }
`;

export const DailyFixedSummaryChartCardChildrenContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: calc(100% - 70px);
`;

export const DailyFixedSummaryChartCardChildContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 4px 12px;

  :nth-child(odd) {
    border-right: 1px solid ${({ theme }) => theme.colors.borderColor};
  }

  :last-child {
    border: 0px;
  }
`;

export const DailyFixedSummaryChartCardChildContainerTitleContainer = styled.div`
  ${flexCenter}
  justify-content: flex-start;
  margin-right: 8px;

  img {
    width: 25px;
    height: 25px;
  }
`;

export const DailyFixedSummaryChartCardChildContainerValue = styled.p`
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.titleColor};

  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textColor};
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
