import { useParams } from "react-router-dom";
import {
  LoadedCompareRightProvider,
  LoadedRightCompare,
} from "../context/loaded-compare-right-context";
import { useGetPatient } from "../../../../../../hooks/patient-hooks/use-get-patient";
import { useGetPatientCGMEntries } from "../../../../../../hooks/use-get-patient-cgm-entries";
import { useCallback } from "react";
import { Loading } from "../../../../../../components/loading";
import { CompareRightResponseHeader } from "./compare-right-response-header";
import { CompareRightResponseStatistics } from "./compare-right-response-statistics";
import { CompareRightTimeRanges } from "./compare-right-time-ranges/compare-right-time-ranges";
import { CompareRightAgpProvider } from "../context/compare-right-agp-context";
import { CompareRightResponseAgp } from "./compare-right-response-agp/compare-right-response-agp";
import { useCompareDateRange } from "../../context/compare-date-context";

export const CompareRightResponse = () => {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatient = useGetPatient();
  const getPatientCGMEntries = useGetPatientCGMEntries();

  const { temporalStartDate, temporalEndDate } = useCompareDateRange();

  const compareRightResponseLoadCallback = useCallback(async (): Promise<
    LoadedRightCompare
  > => {
    const patientDTO = await getPatient(patientID);

    const cgmSource = "Libreview";
    const patientCGMEntryDTOs = await getPatientCGMEntries(
      patientID,
      cgmSource,
      temporalStartDate.right,
      temporalEndDate.right
    );

    return {
      patientDTO,
      patientCGMEntryDTOs,
    };
  }, [
    patientID,
    getPatient,
    getPatientCGMEntries,
    temporalStartDate,
    temporalEndDate,
  ]);

  return (
    <Loading
      load={compareRightResponseLoadCallback}
      successComponent={(data) => (
        <LoadedCompareRightProvider
          patientDTO={data.patientDTO}
          patientCGMEntryDTOs={data.patientCGMEntryDTOs}
        >
          <CompareRightResponseHeader />
          <CompareRightResponseStatistics />
          <CompareRightTimeRanges />
          <CompareRightAgpProvider>
            <CompareRightResponseAgp />
          </CompareRightAgpProvider>
        </LoadedCompareRightProvider>
      )}
    />
  );
};
