import { css } from "styled-components/macro";
import { lightShadowAlt } from "../../../../../styles/global-style";
import styled from "styled-components";

export const MarkerTemplateContainer = css`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 4px;
  border-radius: 4px;
  ${lightShadowAlt}

  p {
    margin: 0px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.titleColor};

    span {
      font-size: 11px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

export const MarkerTemplateMealContainer = styled.div`
  ${MarkerTemplateContainer}
  position: absolute;
  right: 10px;
  top: 0px;
`;

export const MarkerTemplateInsulinContainer = styled.div`
  ${MarkerTemplateContainer}
  position: absolute;
  left: 10px;
  top: -20px;

  p {
    display: flex;
    align-items: center;

    span {
      font-size: 11px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.textColor};
      margin-left: 4px;
    }
  }
`;

export const MarkerTemplateActivitiesContainer = styled.div`
  ${MarkerTemplateContainer}
  position: absolute;
  left: 10px;
  bottom: -10px;
`;

export const MarkerTemplateBGMContainer = styled.div`
  ${MarkerTemplateContainer}
  position: absolute;
  left: -60px;
  bottom: -10px;
  width: 65px;
`;
