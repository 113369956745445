import { useDropdown } from "../../../../../../../../../../../components/dropdown/hooks/use-dropdown";
import { CarbCounterV1MealSummaryDTO } from "../../../../../../../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";
import { PatientMealDTO } from "../../../../../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { roundTo1DecimalPlace } from "../../../../../../../../../../../utils/math-utils";
import {
  MealsAnalysisResponseTableCompareMealsDropdownItemContainer,
  MealsAnalysisResponseTableCompareMealsDropdownTitle,
  MealsAnalysisResponseTableCompareMealsDropdownWrapper,
} from "./styled-meals-analysis-response-table-compare-meals-dropdown";

type MealsAnalysisResponseTableCompareMealsDropdownProps = {
  patientMealDTO: PatientMealDTO;
  carbCounterV1MealSummary?: CarbCounterV1MealSummaryDTO;
};

export const MealsAnalysisResponseTableCompareMealsDropdown = (
  props: MealsAnalysisResponseTableCompareMealsDropdownProps
) => {
  const { patientMealDTO, carbCounterV1MealSummary } = props;

  const foodGroups =
    carbCounterV1MealSummary !== undefined
      ? carbCounterV1MealSummary.foodGroups.filter(
          (item) => item.servingsCount > 0
        )
      : patientMealDTO.patientMealFoodGroups;

  const { dropdownContainerRef, showDropdown, toggleDropdown } = useDropdown();

  return (
    <div ref={dropdownContainerRef}>
      <MealsAnalysisResponseTableCompareMealsDropdownTitle
        onClick={toggleDropdown}
        $showDropdown={showDropdown}
      >
        Meal ingredents
        <span className="material-symbols-outlined">chevron_right</span>
      </MealsAnalysisResponseTableCompareMealsDropdownTitle>
      <MealsAnalysisResponseTableCompareMealsDropdownWrapper
        $showDropdown={showDropdown}
      >
        {carbCounterV1MealSummary && (
          <>
            <div>
              {patientMealDTO.patientMealFoods.map((item, index) => (
                <MealsAnalysisResponseTableCompareMealsDropdownItemContainer
                  key={index}
                >
                  <p>
                    {item.food.displayName
                      ? item.food.displayName
                      : item.food.name}
                  </p>
                  <span>
                    {roundTo1DecimalPlace(item.quantity)}{" "}
                    {item.quantityFoodMeasure.displayName
                      ? item.quantityFoodMeasure.displayName
                      : item.quantityFoodMeasure.name}
                  </span>
                </MealsAnalysisResponseTableCompareMealsDropdownItemContainer>
              ))}
            </div>
          </>
        )}
        <MealsAnalysisResponseTableCompareMealsDropdownTitle>
          Food Groups
        </MealsAnalysisResponseTableCompareMealsDropdownTitle>
        <div>
          {foodGroups.map((foodGroup, index) => (
            <MealsAnalysisResponseTableCompareMealsDropdownItemContainer
              key={index}
            >
              <p>{foodGroup.foodGroup.name}</p>
              <span>{roundTo1DecimalPlace(foodGroup.servingsCount)}</span>
            </MealsAnalysisResponseTableCompareMealsDropdownItemContainer>
          ))}
        </div>
      </MealsAnalysisResponseTableCompareMealsDropdownWrapper>
    </div>
  );
};
