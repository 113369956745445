import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import {
  NavigateOptions,
  URLSearchParamsInit,
  useSearchParams,
} from "react-router-dom";

type DateRangeParams = {
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit)
      | undefined,
    navigateOpts?: NavigateOptions | undefined
  ) => void;
};

const DateRangeParamsContext = createContext<DateRangeParams | undefined>(
  undefined
);

type DateRangeParamsProviderProps = PropsWithChildren<{}>;

export function DateRangeParamsProvider(props: DateRangeParamsProviderProps) {
  const { children } = props;

  const [searchParams, setSearchParams] = useSearchParams({
    startDate: "",
    endDate: "",
  });

  const value = useMemo(
    () => ({
      searchParams,
      setSearchParams,
    }),
    [searchParams, setSearchParams]
  );

  return (
    <DateRangeParamsContext.Provider value={value}>
      {children}
    </DateRangeParamsContext.Provider>
  );
}

export const useDateRangeParams = (): DateRangeParams => {
  const dateRangeParams = useContext(DateRangeParamsContext);

  if (dateRangeParams === undefined) {
    throw new Error(
      `useDateRangeParams must be used within a DateRangeParamsProvider`
    );
  }

  return dateRangeParams;
};
