import styled from "styled-components/macro";
import {
  lightShadowAlt,
  transition,
} from "../../../../../../../styles/global-style";
import { regularSemiBoldFont } from "../../../../../../../styles/classes/gloabl-classes";
import { Button } from "../../../../../../../styles/classes/reusable-classes";

export const MealsAnalysisResponseTableCompareContainer = styled.div<{
  $show: boolean;
}>`
  background: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.buttonColor};
  ${lightShadowAlt}
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0px 8px;
  transform: ${({ $show }) =>
    $show ? "translateY(0px)" : "translateY(100vh)"};
  transition: ${transition};
  width: fit-content;
  position: fixed;
  inset: 0px;
  bottom: 40px;
  top: unset;
  margin: 0 auto;
  z-index: ${({ theme }) => theme.zIndex.z500};

  span {
    ${regularSemiBoldFont}
    cursor: pointer;
  }

  ${Button} {
    padding: 0px 16px;
    background: ${({ theme }) => theme.colors.success};
    margin: 0px 24px;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
  }
`;

export const MealsAnalysisResponseTableCompareText = styled.div`
  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    padding-right: 8px;

    ::after {
      content: "";
      position: absolute;
      height: 20px;
      width: 2px;
      top: 0px;
      right: 0px;
      background-color: ${({ theme }) => theme.colors.borderColor};
      opacity: 0.3;
    }
  }
`;
