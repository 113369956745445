import styled from "styled-components/macro";
import {
  MacrosBarContainer,
  MacrosBarFooter,
} from "../../../../../../../components/macros-bar/styled-macros-bar";

export const NutritionSummaryContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  position: relative;
  border-radius: 8px;
  width: 290px;
  border: 1px solid ${({ theme }) => theme.colors.borderColorAlt};
  border-radius: 12px;
  font-size: 12px;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }

  ${MacrosBarContainer} {
    padding-bottom: 8px;
  }

  ${MacrosBarFooter} {
    margin-top: 0px;
  }
`;

export const NutritionSummaryCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  height: 70px;

  h3 {
    font-size: 1rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.screenTitleColor};
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.newColors.modalContainerAlt};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
`;
