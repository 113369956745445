import { MarkerSettingsModel } from "@syncfusion/ej2-react-charts";
import { PatientMealsSeries } from "../../series/build-patient-meals-series";
import { roundTo1DecimalPlace } from "../../../../../utils/math-utils";
import { MarkerTemplateMealContainer } from "./styled-marker-template";

export function buildPatientMealsMarkerTemplate(): MarkerSettingsModel {
  const template: any = markerTemplate;

  return {
    visible: true,
    border: { width: 0 },
    shape: "Image",
    imageUrl: "/img/daily-fixed-summary/carbs.png",
    width: 20,
    height: 20,
    dataLabel: {
      visible: true,
      template: template,
    },
  };
}

function markerTemplate(args: any) {
  //   console.log(args.series.properties.dataSource);

  const dataSource: PatientMealsSeries[] = args.series.properties.dataSource;

  const findCurrentDataSource = dataSource.find(
    (item) => item.y === args.point.y
  );

  if (
    findCurrentDataSource &&
    findCurrentDataSource.tooltip.kind.kind === "PatientMeal"
  ) {
    const pointDataFromTooltip = findCurrentDataSource.tooltip.kind.data;

    let totalCarbs = 0;

    const carbCounterTotalCarbs =
      pointDataFromTooltip.carbCounterV1MealSummary?.totalCarbsGm;
    const macrosTotalCarbs =
      pointDataFromTooltip.patientMealMacrosSummary.carbsGm;

    totalCarbs = carbCounterTotalCarbs || macrosTotalCarbs;

    return (
      <MarkerTemplateMealContainer>
        <p>
          {roundTo1DecimalPlace(totalCarbs)}
          <span>g</span>
        </p>
      </MarkerTemplateMealContainer>
    );
  }

  return <></>;
}
