import { ModalForm } from "../../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../../components/modal/modal-header/modal-header";
import { PatientDTO } from "../../../../../../../../../models/patient-dtos/patient-dto";
import { PatientMealResponseDTO } from "../../../../../../../../../models/patient-meal-response-dtos/patient-meal-response-dto";
import { MealsAnalysisResponseTableCompareChart } from "./meals-analysis-response-table-compare-chart/meals-analysis-response-table-compare-chart";
import { MealsAnalysisResponseTableCompareMeals } from "./meals-analysis-response-table-compare-meals/meals-analysis-response-table-compare-meals";
import { MealsAnalysisResponseTableCompareModalBodyContainer } from "./styled-meals-analysis-response-table-compare-modal-body";

type MealsAnalysisResponseTableCompareModalBodyProps = {
  closeModal: () => void;
  patientMealResponseDTOs: PatientMealResponseDTO[];
  patientDTO: PatientDTO;
};

export const MealsAnalysisResponseTableCompareModalBody = (
  props: MealsAnalysisResponseTableCompareModalBodyProps
) => {
  const { closeModal, patientMealResponseDTOs, patientDTO } = props;

  return (
    <ModalForm onSubmit={(e) => e.preventDefault()} width={1200} height={800}>
      <ModalHeader title="Compare Meals" onModalClose={closeModal} />
      <MealsAnalysisResponseTableCompareModalBodyContainer>
        <MealsAnalysisResponseTableCompareChart
          patientMealResponseDTOs={patientMealResponseDTOs}
          patientDTO={patientDTO}
        />
        <MealsAnalysisResponseTableCompareMeals
          patientMealResponseDTOs={patientMealResponseDTOs}
        />
      </MealsAnalysisResponseTableCompareModalBodyContainer>
    </ModalForm>
  );
};
