import { MarkerSettingsModel } from "@syncfusion/ej2-react-charts";

export const InsulinMarker: MarkerSettingsModel = {
  visible: true,
  border: { width: 0 },
  shape: "Image",
  imageUrl: "/img/cgm-chart/insulin.png",
  width: 18,
  height: 18,
};
