import { DateRangeContainer } from "../../../../../components/date-range/styled-date-range";
import { Button } from "../../../../../styles/classes/reusable-classes";
import {
  CompareSide,
  SelectedDates,
  useCompareDateRange,
} from "../context/compare-date-context";
import { CompareDateRangeInputPicker } from "./compare-date-range-input-picker";
import { CompareDateRangeSelectedDates } from "./compare-date-range-selected-dates";

type CompareDateRangeProps = {
  temporalStartDate: string;
  temporalEndDate: string;
  onDatesChange: (e: any, compareSide: CompareSide) => void;
  handleIncrementDates: () => void;
  handleDecrementDates: () => void;
  compareSide: CompareSide;
  selectedDates: SelectedDates;
};

export const CompareDateRange = (props: CompareDateRangeProps) => {
  const {
    temporalStartDate,
    temporalEndDate,
    onDatesChange,
    handleIncrementDates,
    handleDecrementDates,
    selectedDates,
    compareSide,
  } = props;

  const { dateRangePickerRef } = useCompareDateRange();

  return (
    <DateRangeContainer>
      <DateRangeContainer>
        <Button
          onClick={() => {
            dateRangePickerRef.current?.show();
          }}
        >
          Date Range
          <CompareDateRangeInputPicker
            temporalStartDate={temporalStartDate}
            temporalEndDate={temporalEndDate}
            onDatesChange={(e) => onDatesChange(e, compareSide)}
          />
        </Button>
        <CompareDateRangeSelectedDates
          handleIncrementDates={handleIncrementDates}
          handleDecrementDates={handleDecrementDates}
          selectedDates={selectedDates}
        />
      </DateRangeContainer>
    </DateRangeContainer>
  );
};
