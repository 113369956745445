import { PropsWithChildren } from "react";
import { DateRangeParamsProvider } from "./date-range-params-context";
import { DateRangeProvider } from "./date-range-context";

type DateRangeProvidersProps = PropsWithChildren<{
  startWithUndefined: boolean;
}>;

export const DateRangeProviders = (props: DateRangeProvidersProps) => {
  const { children, startWithUndefined } = props;

  return (
    <DateRangeParamsProvider>
      <DateRangeProvider startWithUndefined={startWithUndefined}>
        {children}
      </DateRangeProvider>
    </DateRangeParamsProvider>
  );
};
