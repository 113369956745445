import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  flexCol,
  mediumRegularFont,
  regularSemiBoldFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const DailyChartContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 4px 16px;
  display: flex;
  gap: 0rem calc(32px - 13px);
  max-width: 1350px;
  width: 100%;

  @media screen and (max-width: 1440px) {
    padding: 8px calc(27px - 3px);
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    gap: 24px 0px;
    text-align: center;
  }

  @media screen and (max-width: 940px) {
    width: 100%;
    overflow: auto;
  }

  @media screen and (max-width: 375px) {
    padding: 8px 0px;
  }
`;

export const DailyChartCard = styled.div`
  ${flexCol}
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.borderColorAlt};
  width: calc(100% - 290px);
  min-width: 510px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    min-width: unset;
  }
`;

export const DailyChartCardHeader = styled.div`
  ${flexCenterSpaceBetween}
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  height: 70px;

  @media screen and (max-width: 590px) {
    flex-direction: column;
    height: auto;
  }
`;

export const DailyChartCardHeaderTitleContainer = styled.div`
  p {
    ${regularSemiBoldFont}
    margin: 0px;
  }

  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const DailyChartRangesContainer = styled.div<{
  viewPlanChart?: boolean;
}>`
  ${flexCenter}
  justify-content: ${({ viewPlanChart }) =>
    viewPlanChart ? "space-between" : ""};
  width: ${({ viewPlanChart }) => (viewPlanChart ? "100%" : "")};
  gap: ${({ viewPlanChart }) => (viewPlanChart ? "0px" : "0px 16px")};

  @media screen and (max-width: 590px) {
    margin-top: 16px;
  }
`;

export const DailyChartChartContainer = styled.div`
  @media screen and (max-width: 940px) {
    overflow-x: scroll;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      height: 5px;
    }
  }
`;

export const DailyChartLegend = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 10px 24px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px 24px;

  @media screen and (max-width: 1330px) {
    gap: 0px 8px;
  }
`;

export const DailyChartLegendPoint = styled.div<{
  $bgm?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0px 8px;

  img {
    width: ${({ $bgm }) => ($bgm ? "15px" : "20px")};
    height: ${({ $bgm }) => ($bgm ? "15px" : "20px")};
    border-radius: 100%;
  }

  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.lightTextColor};
  }

  @media screen and (max-width: 1330px) {
    gap: 0px 4px;
  }
`;
