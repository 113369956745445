import { Temporal } from "temporal-polyfill";
import { DayAggregate } from "../../../../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { CgmSyncfusionChart } from "../../../../../../../../../components/cgm-syncfusion-chart/cgm-syncfusion-chart";
import { ModalForm } from "../../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { useAGPReportLoadedResponse } from "../../../../../context/agp-report-loaded-response-context";
import { getInRangeNumbers } from "../../../../../../../../../utils/patient-type-utils";
import { DailyChartRanges } from "../../../../../../daily-analysis/daily-charts/daily-chart/daily-chart-ranges/daily-chart-ranges";
import { DailyChartRangesContainer } from "../../../../../../daily-analysis/daily-charts/daily-chart/styled-daily-chart";
import {
  AgpReportResponseChartModalBodyChartContainer,
  AgpReportResponseChartModalBodyChartHeader,
  AgpReportResponseChartModalBodyChartTitle,
  AgpReportResponseChartModalBodyContainer,
  AgpReportResponseChartModalBodyIcon,
} from "./styled-agp-report-response-chart-modal-body";
import { useMemo, useState } from "react";
import { useDateRange } from "../../../../../../../../../components/date-range/context/date-range-context";
import { DailyFixedSummaryChartCard } from "../../../../../../daily-fixed-summary/daily-analysis-charts/daily-fixed-summary-chart-container/daily-fixed-summary-chart-card/daily-fixed-summary-chart-card";

type AgpReportResponseChartModalBodyProps = {
  closeModal: () => void;
  date: string;
  isModalOpen: boolean;
};

export const AgpReportResponseChartModalBody = (
  props: AgpReportResponseChartModalBodyProps
) => {
  const { closeModal, date, isModalOpen } = props;

  const {
    patientDTO,
    patientActivityLogs,
    patientInsulinLogDTOs,
    patientMealsDTOs,
    patientCGMEntryDTOs,
  } = useAGPReportLoadedResponse();

  const [carouselDate, setCarouselDate] = useState(date);

  const { selectedDates } = useDateRange();

  const handleNext = () => {
    const currentDate = Temporal.PlainDate.from(carouselDate);
    const nextDate = currentDate.add({ days: 1 }).toString();

    if (selectedDates.datesWithinRange.some((item) => item.date === nextDate)) {
      setCarouselDate(nextDate);
    }
  };

  const handlePrevious = () => {
    const currentDate = Temporal.PlainDate.from(carouselDate);
    const previousDate = currentDate.subtract({ days: 1 }).toString();

    if (
      selectedDates.datesWithinRange.some((item) => item.date === previousDate)
    ) {
      setCarouselDate(previousDate);
    }
  };

  const dayAggregate: DayAggregate = useMemo(() => {
    const currentPatientCGMEntryDTOs = patientCGMEntryDTOs.filter(
      (item) => item.date === carouselDate
    );

    const currentPatientInsulinLogDTOs = patientInsulinLogDTOs.filter(
      (item) => item.date === carouselDate
    );

    const currentPatientMealsDTOs = patientMealsDTOs.filter(
      (item) => item.date === carouselDate
    );

    const currentPatientActivityLogs = patientActivityLogs.filter(
      (item) => item.date === carouselDate
    );

    const minMaxGlucose = () => {
      const min = Math.min(
        ...[
          40,
          ...currentPatientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL),
        ]
      );

      const max = Math.max(
        ...[
          40,
          ...currentPatientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL),
        ]
      );

      return { min, max };
    };

    return {
      date: Temporal.PlainDate.from(carouselDate),
      inRangeNumbers: getInRangeNumbers(patientDTO.type),
      maxGlucose: minMaxGlucose().max,
      minGlucose: minMaxGlucose().min,
      patientActivityLogs: currentPatientActivityLogs,
      patientCGMEntryDTOs: currentPatientCGMEntryDTOs,
      patientInsulinLogDTOs: currentPatientInsulinLogDTOs,
      patientMealDTO: currentPatientMealsDTOs,
      rangesCounts: {
        above: 0,
        below: 0,
        inRange: 0,
      },
      meals: [],
      medicines: [],
      readings: [],
    };
  }, [
    carouselDate,
    patientCGMEntryDTOs,
    patientActivityLogs,
    patientDTO.type,
    patientInsulinLogDTOs,
    patientMealsDTOs,
  ]);

  const displayDate = Temporal.PlainDate.from(carouselDate).toLocaleString(
    "en-US",
    {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    }
  );

  const modalFormWidth = window.innerWidth >= 1250 ? 1210 : 1100;

  return (
    <ModalForm height={500} width={modalFormWidth}>
      <ModalHeader title="Daily Fixed Summary" onModalClose={closeModal} />
      {isModalOpen && (
        <AgpReportResponseChartModalBodyContainer>
          <AgpReportResponseChartModalBodyIcon
            onClick={handlePrevious}
            className="material-symbols-outlined"
          >
            chevron_left
          </AgpReportResponseChartModalBodyIcon>
          <AgpReportResponseChartModalBodyChartContainer>
            <AgpReportResponseChartModalBodyChartHeader>
              <AgpReportResponseChartModalBodyChartTitle>
                <p>{displayDate}</p>
                <span>Blood glucose reading</span>
              </AgpReportResponseChartModalBodyChartTitle>
              <DailyChartRangesContainer>
                {dayAggregate.patientCGMEntryDTOs.length > 0 && (
                  <DailyChartRanges
                    smallCircles={false}
                    dayAggregate={dayAggregate}
                    patientDTO={patientDTO}
                  />
                )}
              </DailyChartRangesContainer>
            </AgpReportResponseChartModalBodyChartHeader>
            <CgmSyncfusionChart
              patientDTO={patientDTO}
              dayAggregate={dayAggregate}
              zindex={10000}
              hasTooltip={false}
              enableLegend
              cgmChartMarkerTemplate={[
                "Patient Meals",
                "Insulin",
                "Activities",
              ]}
            />
          </AgpReportResponseChartModalBodyChartContainer>
          <DailyFixedSummaryChartCard dayAggregate={dayAggregate} />
          <AgpReportResponseChartModalBodyIcon
            className="material-symbols-outlined"
            onClick={handleNext}
          >
            chevron_right
          </AgpReportResponseChartModalBodyIcon>
        </AgpReportResponseChartModalBodyContainer>
      )}
      <ModalFooter style={{ padding: "8px 16px", marginBottom: "0px" }}>
        <Button type="button" outlined onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
