import {
  ChartComponent,
  DataLabel,
  Inject,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineSeries,
  StripLine,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { PatientMealResponseDTO } from "../../../../../../../../../../models/patient-meal-response-dtos/patient-meal-response-dto";
import { useCallback, useMemo } from "react";
import { randomUUID } from "../../../../../../../../../../utils/random-uuid";
import { PatientDTO } from "../../../../../../../../../../models/patient-dtos/patient-dto";
import { getInRangeNumbers } from "../../../../../../../../../../utils/patient-type-utils";
import { buildPrimaryYAxis } from "../../../../meals-analysis-response-table-rows/meals-analysis-response-table-cells/meals-analysis-response-graph-table-cell/meals-analysis-response-graph-table-cell-graph/utils/build-primary-y-axis";
import { buildAxisLabelRender } from "../../../../meals-analysis-response-table-rows/meals-analysis-response-table-cells/meals-analysis-response-graph-table-cell/meals-analysis-response-graph-table-cell-graph/utils/build-axis-label-render";
import { Temporal } from "temporal-polyfill";
import { buildPatientMealsSeries } from "./utils/series/build-cgm-meals-series";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import { buildInsulinSeries } from "./utils/series/build-insulin-series";
import { InsulinMarker } from "./utils/markers/insulin-marker";
import { MealsMarker } from "./utils/markers/meals-marker";
import { CgmMarker } from "./utils/markers/cgm-marker";
import { buildCgmTooltip } from "../../../../../../../../../../components/cgm-syncfusion-chart/utils/cgm-tooltip/utils/build-cgm-tooltip";
import { buildCGMEntriesReadingsSeries } from "./utils/series/build-cgm-readings-series";
import { MealsAnalysisResponseTableCompareChartContainer } from "./styled-meals-analysis-response-table-compare-chart";

type MealsAnalysisResponseTableCompareChartProps = {
  patientDTO: PatientDTO;
  patientMealResponseDTOs: PatientMealResponseDTO[];
};

export const MealsAnalysisResponseTableCompareChart = (
  props: MealsAnalysisResponseTableCompareChartProps
) => {
  const { patientDTO, patientMealResponseDTOs } = props;

  const patientCGMEntryDTOs = patientMealResponseDTOs
    .map((item) => item.patientCGMEntries)
    .flat();

  const patientMealDTOs = patientMealResponseDTOs.map(
    (item) => item.patientMeal
  );

  const id = useMemo(() => `cgm-syncfusion-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const minY = useMemo(
    () =>
      Math.min(
        ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
      ),
    [patientCGMEntryDTOs]
  );

  const maxY = useMemo(() => {
    let maxGlucose = Math.max(
      ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
    );
    return maxGlucose > 200 ? maxGlucose + 50 : 250;
  }, [patientCGMEntryDTOs]);

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const vAxisTicks = useMemo(() => [...inRangeNumbers, maxY], [
    inRangeNumbers,
    maxY,
  ]);

  const primaryxAxis = useMemo(() => buildPrimaryXAxis(), []);

  const primaryyAxis = useMemo(
    () => buildPrimaryYAxis(minY, maxY, inRangeNumbers),
    [minY, maxY, inRangeNumbers]
  );

  const axisLabelRender = useMemo(() => buildAxisLabelRender(vAxisTicks), [
    vAxisTicks,
  ]);

  const tooltip = useMemo(() => buildCgmTooltip(true), []);

  const scaleChart = (
    percentage: number,
    method: "Increase" | "Decrease"
  ): { chartWidth: number; chartHight: number } => {
    const mealsAnalysisTableChartWidth = 220;
    const mealsAnalysisTableChartHeight = 130;

    let chartWidth = 0;
    let chartHight = 0;

    if (method === "Increase") {
      chartWidth = mealsAnalysisTableChartWidth * (1 + percentage / 100);
      chartHight = mealsAnalysisTableChartHeight * (1 + percentage / 100);
    } else {
      chartWidth = mealsAnalysisTableChartWidth * (percentage / 100);
      chartHight = mealsAnalysisTableChartHeight * (percentage / 100);
    }

    return { chartWidth, chartHight };
  };

  const scaleChartPercentage = 150;

  return (
    <MealsAnalysisResponseTableCompareChartContainer
      $width={`${scaleChart(scaleChartPercentage, "Increase").chartWidth}px`}
    >
      <ChartComponent
        id={id}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        width={`${scaleChart(scaleChartPercentage, "Increase").chartWidth}px`}
        height={`${scaleChart(scaleChartPercentage, "Increase").chartHight}px`}
        loaded={handleChartLoaded}
        axisLabelRender={axisLabelRender}
        border={{ width: 0 }}
        tooltip={tooltip}
        tooltipTemplate={tooltip}
      >
        <Inject
          services={[DataLabel, LineSeries, SplineSeries, StripLine, Tooltip]}
        />
        <SeriesCollectionDirective>
          {patientMealResponseDTOs.map((item, key) => (
            <SeriesDirective
              key={key}
              type="Spline"
              name="CGM Readings"
              dataSource={buildCGMEntriesReadingsSeries(
                patientDTO,
                item.patientCGMEntries,
                Temporal.PlainDate.from(item.chartStartDate).toPlainDateTime(
                  Temporal.PlainTime.from(item.patientMeal.time)
                )
              )}
              xName="x"
              yName="y"
              fill={key === 0 ? "#c8c2ae" : key === 1 ? "#7c6354" : "#b66d0d"}
              marker={CgmMarker}
              width={2}
              opacity={1}
              enableTooltip
              tooltipMappingName="tooltip"
            />
          ))}
          {patientMealResponseDTOs.map((item) => (
            <SeriesDirective
              type="Spline"
              name="Patient Meals"
              dataSource={buildPatientMealsSeries(
                item.patientCGMEntries,
                patientMealDTOs,
                Temporal.PlainDate.from(item.chartStartDate).toPlainDateTime(
                  Temporal.PlainTime.from(item.patientMeal.time)
                )
              )}
              xName="x"
              yName="y"
              fill="transparent"
              marker={MealsMarker}
              enableTooltip={false}
            />
          ))}
          {patientMealResponseDTOs.map((item) => (
            <SeriesDirective
              type="Spline"
              name="Insulin Doses"
              dataSource={buildInsulinSeries(
                Temporal.PlainDate.from(item.chartStartDate).toPlainDateTime(
                  item.patientInsulinLog !== undefined
                    ? Temporal.PlainTime.from(item.patientInsulinLog.time)
                    : Temporal.PlainTime.from(item.patientMeal.time)
                ),
                item.patientCGMEntries,
                item.patientInsulinLogs
              )}
              xName="x"
              yName="y"
              fill="transparent"
              marker={InsulinMarker}
              enableTooltip
              tooltipMappingName="tooltip"
            />
          ))}
        </SeriesCollectionDirective>
      </ChartComponent>
    </MealsAnalysisResponseTableCompareChartContainer>
  );
};
