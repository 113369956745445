import { AxisModel } from "@syncfusion/ej2-react-charts";

export function buildPrimaryXAxis(min: Date, max: Date): AxisModel {
  return {
    title: undefined,
    valueType: "DateTime",
    edgeLabelPlacement: "Shift",
    intervalType: "Hours",
    labelFormat: "hh:mm a",
    majorGridLines: {
      color: "transparent",
      width: 0,
    },
    interval: 3,
    minimum: min,
    maximum: max,
  };
}
