import styled from "styled-components";
import { mediumSemiBoldFont } from "../../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../../styles/global-style";

export const MealsAnalysisResponseTableRow = styled.tr<{ $dimmed: boolean }>`
  max-height: 150px;
  opacity: ${({ $dimmed }) => ($dimmed ? "0.6" : "1")};
  transition: ${transition};

  td {
    max-width: 300px;
    text-align: center;
    padding: 0px 8px;
    ${mediumSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  :nth-child(even) {
    background-color: ${({ theme }) => theme.colors.bodyColor};
  }
`;
