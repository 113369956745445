import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

type MealsAnalysisSelectedTableRows = {
  selectedTableRows: number[];
  setSelectedTableRows: (selectedTableRows: number[]) => void;
};

const MealsAnalysisSelectedTableRowsContext = createContext<
  MealsAnalysisSelectedTableRows | undefined
>(undefined);

type MealsAnalysisSelectedTableRowsProviderProps = PropsWithChildren<{}>;

export function MealsAnalysisSelectedTableRowsProvider(
  props: MealsAnalysisSelectedTableRowsProviderProps
) {
  const { children } = props;

  const [selectedTableRows, setSelectedTableRows] = useState<number[]>([]);

  const value = useMemo(() => ({ selectedTableRows, setSelectedTableRows }), [
    selectedTableRows,
    setSelectedTableRows,
  ]);

  return (
    <MealsAnalysisSelectedTableRowsContext.Provider value={value}>
      {children}
    </MealsAnalysisSelectedTableRowsContext.Provider>
  );
}

export function useMealsAnalysisSelectedTableRows() {
  const MealsAnalysisSelectedTableRows = useContext(
    MealsAnalysisSelectedTableRowsContext
  );

  if (MealsAnalysisSelectedTableRows === undefined) {
    throw new Error(
      `useMealsAnalysisSelectedTableRows must be used within MealsAnalysisSelectedTableRowsProvider`
    );
  }

  return MealsAnalysisSelectedTableRows;
}
