import { useMemo } from "react";
import { DayAggregate } from "../../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import {
  DailyFixedSummaryChartCardChildContainer,
  DailyFixedSummaryChartCardChildContainerTitleContainer,
  DailyFixedSummaryChartCardChildContainerValue,
  DailyFixedSummaryChartCardChildrenContainer,
  DailyFixedSummaryChartCardContainer,
  DailyFixedSummaryChartCardHeader,
} from "./styled-daily-fixed-summary-chart-card";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";

type DailyFixedSummaryChartCardProps = {
  dayAggregate: DayAggregate;
};

export const DailyFixedSummaryChartCard = (
  props: DailyFixedSummaryChartCardProps
) => {
  const { dayAggregate } = props;

  const patientCGMEntryDTOs = dayAggregate.patientCGMEntryDTOs;

  const { average } = useMemo(() => {
    let total = 0;

    if (patientCGMEntryDTOs.length === 0) return { average: 0 };

    for (const patientCGMEntryDTO of patientCGMEntryDTOs.filter(
      (item) => item.source === "Libreview"
    )) {
      const glucoseReading = patientCGMEntryDTO.glucoseMGPerDL;

      total += glucoseReading;
    }

    const average = roundTo1DecimalPlace(total / patientCGMEntryDTOs.length);

    return { average };
  }, [patientCGMEntryDTOs]);

  const patientMealDTOs = dayAggregate.patientMealDTO;

  const { totalCarbs } = useMemo(() => {
    let totalCarbCounter = 0;
    let totalMacrosSummary = 0;

    let totalCarbs = 0;

    for (const patientMealDTO of patientMealDTOs) {
      if (patientMealDTO.carbCounterV1MealSummary) {
        const carbCounterMacrosTotalCarbs =
          patientMealDTO.carbCounterV1MealSummary.totalCarbsGm;

        totalCarbCounter += carbCounterMacrosTotalCarbs;
        totalCarbs = totalCarbCounter;
      } else {
        const patientMealMacrosCarbsGM =
          patientMealDTO.patientMealMacrosSummary.carbsGm;

        totalMacrosSummary += patientMealMacrosCarbsGM;
        totalCarbs = totalMacrosSummary;
      }
    }

    return { totalCarbs: roundTo1DecimalPlace(totalCarbs) };
  }, [patientMealDTOs]);

  const patientInsulinLogDTOs = dayAggregate.patientInsulinLogDTOs;

  const { totalInsulin } = useMemo(() => {
    let totalInsulin = 0;

    for (const patientInsulinLogDTO of patientInsulinLogDTOs) {
      const insulinReading = patientInsulinLogDTO.dose;

      totalInsulin += insulinReading;
    }

    return { totalInsulin: roundTo1DecimalPlace(totalInsulin) };
  }, [patientInsulinLogDTOs]);

  const { numberOfHypos } = useMemo(() => {
    let numberOfHypos: number = 0;

    let previousGlucose: number | null = null;

    for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
      if (
        previousGlucose !== null &&
        patientCGMEntryDTO.glucoseMGPerDL !== null
      ) {
        if (previousGlucose >= 70 && patientCGMEntryDTO.glucoseMGPerDL < 70) {
          numberOfHypos++;
        }
      }

      previousGlucose = patientCGMEntryDTO.glucoseMGPerDL;
    }

    return { numberOfHypos: roundTo1DecimalPlace(numberOfHypos) };
  }, [patientCGMEntryDTOs]);

  const { totalActivity } = useMemo(() => {
    let totalActivity = 0;

    for (const activity of dayAggregate.patientActivityLogs) {
      totalActivity += activity.durationMins;
    }

    return { totalActivity };
  }, [dayAggregate.patientActivityLogs]);

  return (
    <DailyFixedSummaryChartCardContainer>
      <DailyFixedSummaryChartCardHeader>
        <p>Summary</p>
      </DailyFixedSummaryChartCardHeader>
      <DailyFixedSummaryChartCardChildrenContainer>
        <DailyFixedSummaryChartCardChildContainer>
          <DailyFixedSummaryChartCardChildContainerTitleContainer>
            <img
              src="/img/daily-fixed-summary/glucose.png"
              alt="Average Glucose"
            />
          </DailyFixedSummaryChartCardChildContainerTitleContainer>
          <DailyFixedSummaryChartCardChildContainerValue>
            <p>Average Glucose</p>
            {average} <span>mg/dl</span>
          </DailyFixedSummaryChartCardChildContainerValue>
        </DailyFixedSummaryChartCardChildContainer>
        <DailyFixedSummaryChartCardChildContainer>
          <DailyFixedSummaryChartCardChildContainerTitleContainer>
            <img src="/img/daily-fixed-summary/carbs-card.png" alt="Carbs" />
          </DailyFixedSummaryChartCardChildContainerTitleContainer>
          <DailyFixedSummaryChartCardChildContainerValue>
            <p>Total Carbs</p>
            {totalCarbs} <span>grams</span>
          </DailyFixedSummaryChartCardChildContainerValue>
        </DailyFixedSummaryChartCardChildContainer>
        <DailyFixedSummaryChartCardChildContainer>
          <DailyFixedSummaryChartCardChildContainerTitleContainer>
            <img src="/img/daily-fixed-summary/insulin.png" alt="insulin pen" />
          </DailyFixedSummaryChartCardChildContainerTitleContainer>
          <DailyFixedSummaryChartCardChildContainerValue>
            <p>Total Insulin</p>
            {totalInsulin} <span>unit{totalInsulin > 1 ? "s" : ""}</span>
          </DailyFixedSummaryChartCardChildContainerValue>
        </DailyFixedSummaryChartCardChildContainer>
        <DailyFixedSummaryChartCardChildContainer>
          <DailyFixedSummaryChartCardChildContainerTitleContainer>
            <img
              src="/img/daily-fixed-summary/activity.png"
              alt="red arrow pointing down"
            />
          </DailyFixedSummaryChartCardChildContainerTitleContainer>
          <DailyFixedSummaryChartCardChildContainerValue>
            <p>Total Exercise</p>
            {totalActivity} <span>Min{totalActivity > 1 ? "s" : ""}</span>
          </DailyFixedSummaryChartCardChildContainerValue>
        </DailyFixedSummaryChartCardChildContainer>
        <DailyFixedSummaryChartCardChildContainer>
          <DailyFixedSummaryChartCardChildContainerTitleContainer>
            <img
              src="/img/daily-fixed-summary/down.png"
              alt="red arrow pointing down"
            />
          </DailyFixedSummaryChartCardChildContainerTitleContainer>
          <DailyFixedSummaryChartCardChildContainerValue>
            <p>Low Events</p>
            {numberOfHypos} <span>hypo{numberOfHypos > 1 ? "s" : ""}</span>
          </DailyFixedSummaryChartCardChildContainerValue>
        </DailyFixedSummaryChartCardChildContainer>
      </DailyFixedSummaryChartCardChildrenContainer>
    </DailyFixedSummaryChartCardContainer>
  );
};
