import {
  ChartComponent,
  DataLabel,
  DateTime,
  DateTimeCategory,
  Inject,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineSeries,
  StripLine,
  Tooltip,
  Legend,
} from "@syncfusion/ej2-react-charts";
import { useCallback, useMemo, useRef } from "react";
import { randomUUID } from "../../utils/random-uuid";
import { DayAggregate } from "../cgm-google-chart/utils/build-days-aggregates";
import { buildReadingsSereis } from "./utils/series/build-readings-series";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import { buildPrimaryYAxis } from "./utils/build-primary-y-axis";
import { getInRangeNumbers } from "../../utils/patient-type-utils";
import { buildCgmTooltip } from "./utils/cgm-tooltip/utils/build-cgm-tooltip";
import { buildBGMReadingsSereis } from "./utils/series/build-bgm-readings-series";
import { buildInsulinSeries } from "./utils/series/build-insulin-series";
import { buildPatientMealsSeries } from "./utils/series/build-patient-meals-series";
import { buildActivitesSeries } from "./utils/series/build-activities-series";
import { CgmMarker } from "./utils/markers/cgm-marker";
import { BgmMarker } from "./utils/markers/bgm-marker";
import { MealsMarker } from "./utils/markers/meals-marker";
import { ActivitiesMarker } from "./utils/markers/activities-marker";
import { InsulinMarker } from "./utils/markers/insulin-marker";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";
import { buildPatientMealsMarkerTemplate } from "./utils/markers/marker-templates/build-patient-meals-marker-template";
import { buildInsulinMarkerTemplate } from "./utils/markers/marker-templates/build-insulin-marker-template";
import { buildActivitiesMarkerTemplate } from "./utils/markers/marker-templates/build-activities-marker-template";
import { buildBgmMarkerTemplate } from "./utils/markers/marker-templates/build-bgm-marker-template";
import { buildAxisLabelRender } from "./utils/build-axis-label-render";

export type CGMChartMarkerTemplate = [
  "Activities",
  "BGM",
  "Insulin",
  "Meals",
  "CGM",
  "Medications",
  "Patient Meals"
];

export type CGMChartMarker = CGMChartMarkerTemplate[number];

export type CGMChartMarkerArray = CGMChartMarker[];

type CgmSyncfusionChartProps = {
  patientDTO: PatientDTO;
  dayAggregate: DayAggregate;
  zindex: number;
  hasTooltip: boolean;
  cgmChartMarkerTemplate?: CGMChartMarkerArray;
  enableLegend: boolean;
};

export const CgmSyncfusionChart = (props: CgmSyncfusionChartProps) => {
  const {
    patientDTO,
    dayAggregate,
    zindex,
    hasTooltip,
    cgmChartMarkerTemplate,
    enableLegend,
  } = props;

  const id = useMemo(() => `cgm-syncfusion-chart-${randomUUID()}`, []);
  const tooltip = useMemo(() => buildCgmTooltip(hasTooltip), [hasTooltip]);
  const chartRef = useRef<ChartComponent | null>(null);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
      chart.style.zIndex = `${zindex}`;
    }
  }, [id, zindex]);

  const minY = useMemo(
    () =>
      Math.min(
        ...[
          40,
          ...dayAggregate.patientCGMEntryDTOs.map(
            (entry) => entry.glucoseMGPerDL
          ),
        ]
      ),
    [dayAggregate.patientCGMEntryDTOs]
  );

  const { maxY } = useMemo(() => {
    let maxGlucose = Math.max(
      ...[
        40,
        ...dayAggregate.patientCGMEntryDTOs.map(
          (entry) => entry.glucoseMGPerDL
        ),
      ]
    );
    const maxY = maxGlucose > 200 ? maxGlucose + 50 : 250;

    return { maxY: Math.floor(maxY / 10) * 10 };
  }, [dayAggregate.patientCGMEntryDTOs]);

  const primaryxAxis = useMemo(
    () =>
      buildPrimaryXAxis(
        new Date(`${dayAggregate.date}T00:00:00`),
        new Date(`${dayAggregate.date}T23:59:59`)
      ),
    [dayAggregate.date]
  );

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const vAxisTicks = useMemo(() => [...inRangeNumbers, maxY], [
    inRangeNumbers,
    maxY,
  ]);

  const axisLabelRender = useMemo(() => buildAxisLabelRender(vAxisTicks), [
    vAxisTicks,
  ]);

  const primaryyAxis = useMemo(() => {
    const title = "";

    return buildPrimaryYAxis(minY, maxY, inRangeNumbers, title);
  }, [minY, maxY, inRangeNumbers]);

  const readingsSeries = useMemo(
    () =>
      buildReadingsSereis(dayAggregate.patientCGMEntryDTOs, patientDTO.type),
    [dayAggregate.patientCGMEntryDTOs, patientDTO.type]
  );

  const bgmReadingsSeries = useMemo(
    () =>
      buildBGMReadingsSereis(dayAggregate.patientCGMEntryDTOs, patientDTO.type),
    [dayAggregate.patientCGMEntryDTOs, patientDTO.type]
  );

  const patientMealsSeries = useMemo(
    () =>
      buildPatientMealsSeries(
        dayAggregate.patientCGMEntryDTOs,
        dayAggregate.patientMealDTO
      ),
    [dayAggregate.patientCGMEntryDTOs, dayAggregate.patientMealDTO]
  );

  const activitiesSeries = useMemo(
    () =>
      buildActivitesSeries(
        dayAggregate.patientCGMEntryDTOs,
        dayAggregate.patientActivityLogs
      ),
    [dayAggregate.patientCGMEntryDTOs, dayAggregate.patientActivityLogs]
  );

  const insulinSeries = useMemo(
    () =>
      buildInsulinSeries(
        dayAggregate.patientCGMEntryDTOs,
        dayAggregate.patientInsulinLogDTOs
      ),
    [dayAggregate.patientCGMEntryDTOs, dayAggregate.patientInsulinLogDTOs]
  );

  const customPatientMealMarker = useMemo(
    () => buildPatientMealsMarkerTemplate(),
    []
  );

  const customActivitiesMarker = useMemo(
    () => buildActivitiesMarkerTemplate(),
    []
  );

  const customBgmMarker = useMemo(() => buildBgmMarkerTemplate(), []);

  const customInsulinMarker = useMemo(() => buildInsulinMarkerTemplate(), []);

  const pointClick = useCallback(() => {
    const chart = chartRef.current;

    if (chart) {
      chart.tooltip.fadeOutDuration = 1000000;
    }
  }, []);

  const chartMouseLeave = useCallback(() => {
    const chart = chartRef.current;

    if (chart) {
      chart.tooltip.fadeOutDuration = 1;
    }
  }, []);

  return (
    <ChartComponent
      id={id}
      primaryXAxis={primaryxAxis}
      primaryYAxis={primaryyAxis}
      height={enableLegend ? "215px" : "180px"}
      width="100%"
      loaded={handleChartLoaded}
      axisLabelRender={axisLabelRender}
      tooltip={tooltip}
      border={{ width: 0 }}
      tooltipTemplate={tooltip}
      pointClick={pointClick}
      ref={chartRef}
      chartMouseLeave={chartMouseLeave}
      legendSettings={{
        visible: enableLegend,
        position: "Bottom",
        shapeWidth: 15,
        shapeHeight: 15,
        alignment: "Near",
      }}
    >
      <Inject
        services={[
          DataLabel,
          DateTime,
          DateTimeCategory,
          Tooltip,
          LineSeries,
          SplineSeries,
          StripLine,
          Legend,
        ]}
      />
      <SeriesCollectionDirective>
        <SeriesDirective
          type="Spline"
          name="CGM Readings"
          dataSource={readingsSeries}
          xName="x"
          yName="y"
          fill="#686868"
          marker={CgmMarker}
          width={2}
          opacity={0.5}
          enableTooltip
          tooltipMappingName="tooltip"
          splineType="Cardinal"
          cardinalSplineTension={0.5}
        />
        <SeriesDirective
          type="Spline"
          name="BGM Readings"
          dataSource={bgmReadingsSeries}
          xName="x"
          yName="y"
          fill="transparent"
          legendImageUrl="/img/cgm-chart/bgm-readings.png"
          legendShape="Image"
          marker={
            cgmChartMarkerTemplate?.includes("BGM")
              ? customBgmMarker
              : BgmMarker
          }
          enableTooltip
          tooltipMappingName="tooltip"
        />
        <SeriesDirective
          type="Spline"
          name="Carbs Intake"
          dataSource={patientMealsSeries}
          xName="x"
          yName="y"
          fill="transparent"
          legendImageUrl="/img/daily-fixed-summary/carbs.png"
          legendShape="Image"
          marker={
            cgmChartMarkerTemplate?.includes("Patient Meals")
              ? customPatientMealMarker
              : MealsMarker
          }
          enableTooltip
          tooltipMappingName="tooltip"
        />
        <SeriesDirective
          type="Spline"
          name="Activities"
          dataSource={activitiesSeries}
          xName="x"
          yName="y"
          fill="transparent"
          legendImageUrl="/img/cgm-chart/activity.png"
          legendShape="Image"
          marker={
            cgmChartMarkerTemplate?.includes("Activities")
              ? customActivitiesMarker
              : ActivitiesMarker
          }
          enableTooltip
          tooltipMappingName="tooltip"
        />
        <SeriesDirective
          type="Spline"
          name="Insulin Doses"
          dataSource={insulinSeries}
          xName="x"
          yName="y"
          fill="transparent"
          legendImageUrl="/img/cgm-chart/insulin.png"
          legendShape="Image"
          marker={
            cgmChartMarkerTemplate?.includes("Insulin")
              ? customInsulinMarker
              : InsulinMarker
          }
          enableTooltip
          tooltipMappingName="tooltip"
        />
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};
